import { Banner } from '../utility-components/Banner';
import BlogsBannerImg from '../../assets/images/blogs/BlogsBannerImg.png';

export const BlogsBanner = (): JSX.Element => {
  const blogsBannerData = {
    imageLoc: BlogsBannerImg,
    fillColor: '#2075AD',
    bannerTitle: 'Blogs',
    bannerSubtitle: null,
    bannerText:
      'Stay connected with our team and read more about our processes, approaches, and lifestyle.',
    bannerButtons: false
  };

  return (
    <Banner
      fillColor={blogsBannerData.fillColor}
      imageLoc={blogsBannerData.imageLoc}
      bannerTitle={blogsBannerData.bannerTitle}
      bannerSubtitle={blogsBannerData.bannerSubtitle}
      bannerText={blogsBannerData.bannerText}
      bannerButtons={blogsBannerData.bannerButtons}
    />
  );
};
