import cyd from '../../assets/images/meet-the-team/cyd.png';
import kristin from '../../assets/images/meet-the-team/kristin.png';
import james from '../../assets/images/meet-the-team/james.png';
import tim from '../../assets/images/meet-the-team/tim.png';
import diane from '../../assets/images/meet-the-team/diane.png';
import emmie from '../../assets/images/meet-the-team/emmie.png';
import amir from '../../assets/images/meet-the-team/amir.png';
import josh from '../../assets/images/meet-the-team/josh.png';

export const colleagueData = [
  {
    imageLoc: cyd,
    imageAlt: '',
    colleagueName: 'Cydney Marsh',
    colleagueTitle: 'UX Research Analyst',
    colleagueInfo:
      'Cydney hails from the Arizona Sonoran Desert. She has deep knowledge of psychology, sociology, ethnography, and design. She can teach you how to test your designs, and how to eat fire.',
    linkedInHyperLink: 'https://www.linkedin.com/in/cydney-nichole-marsh/'
  },
  {
    imageLoc: kristin,
    imageAlt: '',
    colleagueName: 'Kristin Fowler',
    colleagueTitle: 'Senior UX Designer',
    colleagueInfo:
      'Kristin was born and raised in Tennessee and received her BFA in graphic design at Watkins College of Art Design and Film. Kristin enjoys reading, video games, and weightlifting.',
    linkedInHyperLink: 'https://www.linkedin.com/in/kristin-fowler-815b4963/'
  },
  {
    imageLoc: james,
    imageAlt: '',
    colleagueName: 'James Matchett',
    colleagueTitle: 'Lead UX Designer',
    colleagueInfo:
      'James hails from a small town in Ohio and studied industrial design at the University of Cincinnati. He enjoys tabletop gaming and woodworking. In a previous job he designed Mr Potato Head pieces.',
    linkedInHyperLink: 'https://www.linkedin.com/in/jamesmatchett/'
  },
  {
    imageLoc: tim,
    imageAlt: '',
    colleagueName: 'Tim Veilleux',
    colleagueTitle: 'UX Designer',
    colleagueInfo:
      'Tim grew up in southeast Massachusetts before moving to Nashville in 2015. Tim didn’t eat his first taco until he was 27.',
    linkedInHyperLink: 'https://www.linkedin.com/in/tveilleux/'
  },
  {
    imageLoc: diane,
    imageAlt: '',
    colleagueName: 'Diane Tomlins',
    colleagueTitle: 'Accessibility Subject Matter Expert',
    colleagueInfo:
      'Diane has worked in IT for over 30 years. in many roles. Accessibility has been a passion for 15.  Diane is a descendent of Mayflower passenger William White.',
    linkedInHyperLink: 'https://www.linkedin.com/in/diane-tomlins-3593aa2/'
  },
  {
    imageLoc: emmie,
    imageAlt: '',
    colleagueName: 'Emmie Roberts',
    colleagueTitle: 'UX Designer',
    colleagueInfo:
      'Emmie is a rare native Nashvillian. She is a graduate of Lipscomb University. Her hobbies are tea parties, sewing, and listening to jazz.',
    linkedInHyperLink: 'https://www.linkedin.com/in/emma-roberts-306a671a7/'
  },
  {
    imageLoc: amir,
    imageAlt: '',
    colleagueName: 'Amir Fathi',
    colleagueTitle: 'Product Analyst II',
    colleagueInfo:
      'Amir is a Nashville native (Unicorn) and holds a Business information Systems major. Amir enjoys trying new foods, futbal, music, and traveling.',
    linkedInHyperLink: 'https://www.linkedin.com/in/amir-fathi/'
  },
  {
    imageLoc: josh,
    imageAlt: '',
    colleagueName: 'Josh Owens',
    colleagueTitle: 'UX Designer',
    colleagueInfo:
      'Josh is an avid runner, cyclist, and coffee enthusiast. He enjoys spending time with his wife and kids. Josh majored in music at Welch College, but discovered a love for design when he didn’t receive a record deal. ',
    linkedInHyperLink: 'https://www.linkedin.com/in/josh-owens-12750150/'
  }
];
