import { NeuRow } from '@neutron/react';
import { PrincipleElement } from './PrincipleElement';
import checkMark from '../../assets/icons/guiding-principles/checkmark.svg';
import efficient from '../../assets/icons/guiding-principles/efficient.svg';
import consistent from '../../assets/icons/guiding-principles/consistent.svg';
import scalable from '../../assets/icons/guiding-principles/scalable.svg';

export const GuidingPrinciples = () => {
  const guidingPrincipleData = [
    {
      imageLoc: checkMark,
      imageAlt: '',
      title: 'Simple',
      content:
        'Leverage best practices in UI design and centralize them in a shared location.'
    },
    {
      imageLoc: efficient,
      imageAlt: '',
      title: 'Efficient',
      content:
        'Allow designers and developers on any product to immediately pull from well-defined components and patterns.'
    },
    {
      imageLoc: consistent,
      imageAlt: '',
      title: 'Consistent',
      content:
        'Create a streamlined experience for users as they traverse HCA Healthcare’s product suite.'
    },
    {
      imageLoc: scalable,
      imageAlt: '',
      title: 'Scalable',
      content:
        "Build components that can grow and adapt to inherit any brand's style or platform."
    }
  ];

  return (
    <>
      {/*Desktop*/}
      <h2 className="neu-text-weight--heavy neu-text-md-left mb-6 d-none d-md-block align-self-start">
        Neutron’s Core and Guiding Principles
      </h2>
      {/*Mobile*/}
      <p className="neu-text-weight--heavy neu-text--h5 neu-text-md-left mb-3 d-md-none neu-text-center">
        Neutron’s Core and Guiding Principles
      </p>
      <section className="d-flex flex-row justify-content-center">
        <NeuRow>
          {guidingPrincipleData.map((principle, key) => {
            return (
              <PrincipleElement
                imageLoc={principle.imageLoc}
                imageAlt={principle.imageAlt}
                title={principle.title}
                content={principle.content}
                key={key}
              />
            );
          })}
        </NeuRow>
      </section>
    </>
  );
};
