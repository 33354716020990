import { NeuCol, NeuRow, NeuDivider } from '@neutron/react';
import { BannerProps } from '../../types/interfaces';

export const Banner = ({
  bannerTitle,
  bannerSubtitle,
  bannerText,
  imageLoc,
  fillColor
}: BannerProps): JSX.Element => {
  return (
    <NeuRow
      style={{
        backgroundImage: `url(${imageLoc})`
      }}
      className="cover-image vw-100 mx-0 justify-content-center"
    >
      {/* Desktop */}
      <svg
        preserveAspectRatio="none"
        className="d-none d-md-block svg-banner"
        width="70%"
        height="407"
        viewBox="0 0 842 407"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M796.122 407C822.626 267.075 822.626 139.899 796.122 0H-24V407H796.122Z"
          fill={fillColor}
          fillOpacity={0.9}
        />
      </svg>

      {/* MOBILE */}
      <svg
        preserveAspectRatio="none"
        className="d-md-none svg-banner"
        width="150%"
        height="719"
        viewBox="0 0 842 407"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M796.122 407C822.626 267.075 822.626 139.899 796.122 0H-24V407H796.122Z"
          fill={fillColor}
          fillOpacity={0.7}
        />
      </svg>
      <NeuCol
        md={10}
        className="d-flex flex-column justify-content-start px-md-0 mt-6 mt-md-0 pt-6 pt-md-0 justify-content-md-center"
      >
        <NeuRow className="ml-md-3 ">
          <NeuCol
            md={8}
            className="mx-4 mx-md-0 pl-md-0 d-flex flex-column justify-content-center neu-text-center neu-text-md-left neu-text--plain-0 banner-text"
          >
            {/* Desktop */}
            <h2 className="d-none d-md-block neu-text-weight--bold mb-1">
              {bannerTitle}
            </h2>
            {/* Desktop */}
            <h3 className="d-md-none neu-text-weight--bold mb-1 neu-text-start">
              {bannerTitle}
            </h3>
            {/* Sometimes there's a subtitle. Conditionally render based on whether that value is null */}
            {bannerSubtitle !== null ? (
              <>
                {/* Desktop */}
                <h3 className="d-none d-md-block neu-text-weight--bold">
                  {bannerSubtitle}
                </h3>
                {/* MOBILE */}
                <h6 className="d-md-none neu-text-start">{bannerSubtitle}</h6>
              </>
            ) : null}

            <NeuDivider color="plain-0" className="my-6" />
            {/* Desktop */}
            <p className="d-none d-md-block neu-text--h5">{bannerText}</p>
            {/* MOBILE */}
            <h3 className="d-md-none neu-text-weight--bold neu-text-start">
              {bannerText}
            </h3>
          </NeuCol>
        </NeuRow>
      </NeuCol>
    </NeuRow>
  );
};
