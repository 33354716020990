import { NeuImage } from "@neutron/react";

type UXUIComponentProps = {
  imageLoc: string;
  imageAlt: string;
  title: string;
  slug: string;
}

export const UXUIComponent = ({imageLoc, imageAlt, title, slug}: UXUIComponentProps):JSX.Element => (
  
  <div className="d-flex flex-column align-items-center justify-content-between">
    <NeuImage className="d-flex" src={imageLoc} alt={imageAlt} maxWidth="100%" height="auto" fluid/>
    <h5>{title}</h5>
    <p className="flex-fill my-5 neu-text-start">{slug}</p>
  </div>
);
