import { drawerController } from '@neutron/core';
import {
  NeuDrawer,
  NeuContent,
  NeuDrawerToggle,
  NeuList,
  NeuItem,
  NeuIcon
} from '@neutron/react';
import { useNavigate } from 'react-router-dom';

export const Drawer = () => {
    const navigate = useNavigate()

    const touchedListItem = (path:string) => {
        navigate(path)
        drawerController.toggle("first")
    }
  return (
    <NeuDrawer side="start" drawer-id="first" content-id="drawerContent">
      <NeuContent id="drawerContent" nav>
        <NeuList lines="full">
          <NeuDrawerToggle auto-hide="true" drawer="first">
            <NeuItem
              aria-label="Close Drawer"
              button
              focusable
              nav
              role="listitem"
            >
              <NeuIcon slot="start" large>
                close
              </NeuIcon>
              Close
            </NeuItem>
          </NeuDrawerToggle>
          <NeuItem role="listitem" focusable onTouchStart={() => touchedListItem('/')} lines="inset" nav>
            <NeuIcon slot="start" large>
              home
            </NeuIcon>
            Home
          </NeuItem>
          <NeuItem
            detail
            focusable
            onTouchStart={() => touchedListItem('/starter-files')}
            nav
            role="listitem"
          >
            Get Started
          </NeuItem>
          <NeuItem
            detail
            focusable
            onTouchStart={() => touchedListItem('/neutron-design-system')}
            nav
            role="listitem"
          >
            Neutron
          </NeuItem>
          <NeuItem
            detail
            focusable
            onTouchStart={() => touchedListItem('/accessibility')}
            nav
            role="listitem"
          >
            Accessibility
          </NeuItem>
          <NeuItem
            detail
            focusable
            onTouchStart={() => touchedListItem('/our-impact')}
            nav
            role="listitem"
          >
            Our Impact
          </NeuItem>
          <NeuItem
            detail
            focusable
            onTouchStart={() => touchedListItem('/meet-the-team')}
            nav
            role="listitem"
          >
            Meet The Team
          </NeuItem>
          <NeuItem
            detail
            focusable
            onTouchStart={() => touchedListItem('/blogs')}
            nav
            role="listitem"
          >
            Blogs
          </NeuItem>
          <NeuItem
            detail
            focusable
            onTouchStart={() => touchedListItem('/connect')}
            nav
            role="listitem"
          >
            Connect
          </NeuItem>
        </NeuList>
      </NeuContent>
    </NeuDrawer>
  );
};
