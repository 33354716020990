import {
    NeuCard,
    NeuImage,
} from "@neutron/react";

type CardNoButtonProps = {
    quoteText: string;
    imageLoc: string;
    imageAlt: string;
};

//used in TheImpact.tsx
export const CardNoButton = ({
    quoteText,
    imageLoc,
    imageAlt,
}: CardNoButtonProps) => (
    <NeuCard className="p-6 m-6 d-flex flex-column align-items-center justify-content-around">
        {/*Desktop*/}
        <p className="neu-text--h5 mb-6 d-none d-md-block">{quoteText}</p>
        {/*Mobile*/}
        <p className="neu-text--h6 mb-6 d-md-none">{quoteText}</p>
        <NeuImage className="mt-6 align-self-end d-flex" src={imageLoc} alt={imageAlt} fluid/>
    </NeuCard>
  );

