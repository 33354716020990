import { NeuContainer, NeuCol, NeuRow } from '@neutron/react';
import { ConnectForm } from '../utility-components/ConnectForm';

export const ConnectWithUs = () => {
  return (
    <NeuContainer fluid>
      <NeuRow className="justify-content-center my-6">
        <NeuCol sm={12} md={10}>
          <ConnectForm />
        </NeuCol>
      </NeuRow>
    </NeuContainer>
  );
};
