import '../Uxcoe.scss';
import {
  NeuButton,
  NeuCol,
  NeuIcon,
  NeuRow
} from '@neutron/react';
import { ListCell } from './StarterFileListCell';
import { Cell } from '../../types/interfaces';

type StarterFileListProps = {
  cells: Cell[];
  scrollToTop: () => void
};

export const StarterFileList = ({
  cells, scrollToTop
}: StarterFileListProps): JSX.Element => {
  return (
    <section className="min-vh-100 mt-md-6 ">
      {cells.length !== 0 ? (
        <>
          {cells.map((cell, key) => {
            return (
              <ListCell
                key={key}
                imageLoc={cell.imageLoc}
                imageAlt={cell.imageAlt}
                cellTitle={cell.title}
                cellDate={cell.date}
                cellContent={cell.content}
                cellIconLoc={cell.iconLoc}
                cellIconAlt={cell.iconAlt}
                capacityText={cell.capacityText}
                capacityTextColor={cell.capacityTextColor}
                cellPrimaryButtonTitle={cell.primaryButtonTitle}
                cellPrimaryButtonLink={cell.primaryButtonLink}
              />
            );
          })}
          <NeuRow className="justify-content-center">
            <NeuCol className="d-flex justify-content-center" sm={12}>
              <NeuButton
                className="d-md-none"
                color="primary"
                size="large"
                type="button"
                fill="flat"
                onClick={() => scrollToTop()}
              >
                <NeuIcon color="primary" btn slot="end">
                  expand_less
                </NeuIcon>
                Scroll To Top
              </NeuButton>
            </NeuCol>
          </NeuRow>
        </>
      ) : (
        <h5 className="d-flex justify-content-center neu-text--gray-60">
          No results found meeting search and filter settings.
        </h5>
      )}
    </section>
  );
};
