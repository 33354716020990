import '../Uxcoe.scss';
import { NeuCol, NeuContainer, NeuRow } from '@neutron/react';
import { ConnectForm } from '../utility-components/ConnectForm';
import { Partnerships } from './Partnerships';
import { Templates } from './Templates';
import { Testimonials } from './Testimonials';
import { UXUI } from './UX-UI';
import { GetStarted } from './GetStarted';
import { HeroBanner } from './HeroBanner';
import { Footer } from '../footer/Footer';
import { Goals } from '../utility-components/Goals';

export const Home = (): JSX.Element => (
  <NeuContainer fluid>
    <NeuRow>
      <NeuCol>
        <HeroBanner />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
        <Goals />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
        <GetStarted />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
        <UXUI />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10} className="d-none d-md-block">
        <Templates />
      </NeuCol>
    </NeuRow>
    <NeuRow className="colleague-quote justify-content-center my-6">
      <NeuCol sm={12} md={10}>
        <Testimonials />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
        <Partnerships />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
        <ConnectForm />
      </NeuCol>
    </NeuRow>
    <NeuRow>
      <Footer />
    </NeuRow>
  </NeuContainer>
);
