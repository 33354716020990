import {
  NeuCol,
  NeuRow,
  NeuContainer,
  NeuSearchbar
} from '@neutron/react';

type SearchBarProps = {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
};

const SearchBar = ({ searchQuery, setSearchQuery }: SearchBarProps) => {
  return (
    <NeuRow>
      <NeuCol class="d-flex flex-column">
        <h6 className="mb-3">Search Templates By Name</h6>
        <NeuContainer fluid>
          <NeuSearchbar
            value={searchQuery}
            onKeyUp={keyEvent =>
              setSearchQuery((keyEvent.target as HTMLInputElement).value)
            }
            type="text"
            id="header-search"
            aria-label="search"
            button
          ></NeuSearchbar>
        </NeuContainer>
      </NeuCol>
    </NeuRow>
  );
};

export default SearchBar;
