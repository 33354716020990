import '../Uxcoe.scss';
import { NeuCol, NeuRow } from '@neutron/react';
import { GoalsComponent } from '../our-impact/GoalsComponent';

// import educateLogo from '../../assets/images/ourimpact/Educate.png';
// import empowerLogo from '../../assets/images/ourimpact/Empower.png';
// import evangelizeLogo from '../../assets/images/ourimpact/Evangelize.png';
import educateLogo from '../../assets/images/goals/goal-1.svg';
import empowerLogo from '../../assets/images/goals/goal-2.svg';
import evangelizeLogo from '../../assets/images/goals/goal-3.svg';

export const Goals = (): JSX.Element => {
  let goalsData = [
    {
      imageLoc: educateLogo,
      imageAlt: '',
      goal:'Educate',
      slug: 'Showing users the importance of UX design for the community.'
    },
    {
      imageLoc: empowerLogo,
      imageAlt: '',
      goal: 'Empower',
      slug: 'Showing users they can easily be a part of the UX community.'
    },
    {
      imageLoc: evangelizeLogo,
      imageAlt: '',
      goal: 'Evangelize',
      slug: 'Spreading the word about the UX community to everyone.'
    }
  ];
  let path = window.location.pathname;

  return (
    <>
      {/* This component is used a few different places. Render the title based on the the url path */}
      {path.includes('our-impact') ? (
        <>
          <h2 className="neu-text-weight--heavy neu-text-md-left mb-6 pb-6 d-none d-md-block">
            Goals
          </h2>
          <p className="neu-text--h5 neu-text-center mb-6 d-md-none ">Goals</p>
        </>
      ) : (
        <h2 className="neu-text-weight--heavy neu-text neu-text-center neu-text-md-left mb-6 pb-6">
          Our Goals
        </h2>
      )}

      <NeuRow className="justify-content-md-around">
        {goalsData.map((object, key) => {
          return (
            <NeuCol
              key={key}
              sm={12}
              md={4}
              className="d-flex justify-content-center justify-content-md-between "
            >
              <GoalsComponent
                imageLoc={object.imageLoc}
                imageAlt={object.imageAlt}
                goal={object.goal}
                slug={object.slug}
              />
            </NeuCol>
          );
        })}
      </NeuRow>
    </>
  );
};
