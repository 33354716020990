import { NeuCol, NeuRow } from '@neutron/react';
import { PortraitImageCard } from '../utility-components/PortraitImageCard';

import powerPointImage from '../../assets/images/neutron-page/get-started/power-point-card.png';
import videoIcon from '../../assets/icons/neutron-get-started/video.svg';
import question from '../../assets/icons/neutron-get-started/question.svg';
import examples from '../../assets/icons/neutron-get-started/neutron-examples.svg';
import computerMonitor from '../../assets/icons/neutron-get-started/computer-monitor.svg';
import { CallOutBox } from '../utility-components/CallOutBox';

export const GetStartedNeutron = () => {
  const callOutData = [
    {
      imageLoc: videoIcon,
      imageAlt: 'grey circle with orange play button triangle',
      callOutText: 'Neutron Video Tutorials',
      link: 'https://hcadmx.invisionapp.com/dsm/hcadmx/neutron/nav/5fa7da2742ad0f001826f24f/folder/5ec6ee88af04db4846ca0032?version=618bcbe9a0d5d8d0416cc656&mode=preview'
    },
    {
      imageLoc: question,
      imageAlt: 'grey circle with orange question mark inside',
      callOutText: 'Common Questions',
      link: 'https://hcadmx.invisionapp.com/dsm/hcadmx/neutron/nav/5fa7da2742ad0f001826f24f/folder/5e948ea10922557b816b40b9?version=618bcbe9a0d5d8d0416cc656&mode=preview'
    },
    {
      imageLoc: examples,
      imageAlt: 'iconographic representation of website wire frame',
      callOutText: 'Examples of Neutron',
      link: 'https://hcadmx.invisionapp.com/dsm/hcadmx/neutron/nav/5fa7da2742ad0f001826f250/folder/5f217fdcc19236bff2e92ce9?mode=preview&version=618bcbe9a0d5d8d0416cc656'
    }
  ];

  const smallScreenSize = window.matchMedia('(max-width: 767.98px)');
  return (
    <>
      <NeuRow className="d-flex mb-4">
        <NeuCol md={8} cols={12}>
          {/*Desktop*/}
          <h2 className="neu-text-weight--heavy neu-text-md-left mb-6 d-none d-md-block">
            Get Started With Neutron
          </h2>
          {/*Mobile*/}
          <h5 className="neu-text-weight--heavy neu-text-center mb-3 d-md-none">
            Get Started With Neutron
          </h5>
        </NeuCol>
      </NeuRow>
      <NeuRow className="d-flex flex-column flex-md-row">
        <NeuCol
          class="d-none d-md-block d-md-flex align-items-stretch"
          md={5}
          cols={12}
        >
          <PortraitImageCard
            imageLoc={powerPointImage}
            imageAlt="several powerpoint slides loosely arranged"
            contentTitle="Neutron PowerPoint"
            content="The UXCOE created neutron. It’s a Design System that is now available for any role at HCA Healthcare."
            buttonText="Start Creating With Neutron for Powerpoint"
            raised
            iconSlot={false}
            downloadLink="https://hcadmx.invisionapp.com/dsm/hcadmx/neutron/nav/5fa7da2742ad0f001826f24f/folder/6109b9de036d538019fc854c?version=618bcbe9a0d5d8d0416cc656&mode=preview"
          />
        </NeuCol>
        <NeuCol
          className="d-flex flex-column justify-content-around"
          md={6}
          cols={12}
        >
          {callOutData.map((callOut, key) => {
            return (
              <CallOutBox
                key={key}
                imageLoc={callOut.imageLoc}
                imageAlt={callOut.imageAlt}
                callOutText={callOut.callOutText}
                link={callOut.link}
                route={{
                  pathname: undefined,
                  hash: undefined,
                  target: undefined
                }}
              />
            );
          })}
          {/*Adds an additional callout box when in mobile screen size*/}
          {smallScreenSize.matches && (
            <CallOutBox
              imageLoc={computerMonitor}
              imageAlt={''}
              callOutText={'Neutron Power Point'}
              link='https://hcadmx.invisionapp.com/dsm/hcadmx/neutron/nav/5fa7da2742ad0f001826f24f/folder/6109b9de036d538019fc854c?version=618bcbe9a0d5d8d0416cc656&mode=preview'
              route={{
                pathname: undefined,
                hash: undefined,
                target: undefined
              }}
            />
          )}
        </NeuCol>
      </NeuRow>
    </>
  );
};
