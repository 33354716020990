import { NeuButton, NeuCard, NeuCol, NeuImage } from '@neutron/react';

interface ColleagueCardProps {
  imageAlt: string;
  imageLoc: string;
  colleagueName: string;
  colleagueTitle: string;
  colleagueInfo: string;
  linkedInHyperLink: string;
}

export const ColleagueCard = ({
  imageAlt,
  imageLoc,
  colleagueName,
  colleagueTitle,
  colleagueInfo,
  linkedInHyperLink
}: ColleagueCardProps) => {
  return (
    <NeuCol className="d-flex flex-fill" sm={12} md={4}>
      <NeuCard
        className="d-flex flex-column justify-content-start"
        width="fit-content"
      >
        <NeuImage
          alt={`colleague ${colleagueName}`}
          className="align-self-center d-flex"
          fluid
          src={imageLoc}
          minHeight='151px'
        />
        <h5>{colleagueName}</h5>
        <div className="neu-text--caption neu-text--gray-80" >
          {colleagueTitle}
        </div>
        <p className="flex-grow-1 neu-text--body mt-4">
          {colleagueInfo}
        </p>
        <NeuButton className="mt-2" href={linkedInHyperLink} target='_blank'>
          LinkedIn
        </NeuButton>
      </NeuCard>
    </NeuCol>
  );
};
