import '../Uxcoe.scss';
import { NeuCol, NeuRow } from '@neutron/react';
import { CardNoButton } from './CardNoButton';
import '../Uxcoe.scss';

import ibmLogo from '../../assets/images/ourimpact/IBM.png'
import forresterLogo from '../../assets/images/ourimpact/forrester-logo.png'

export const TheImpact = (): JSX.Element => {
  let theImpactData = [
    {
      quoteText:
        `"For every single $1 invested in usability research and design, your company returns $10 to $100 in profit."`,
      imageLoc: ibmLogo,
      imageAlt: 'IBM',
    },
    {
      quoteText:
        `“A well-designed interface could raise conversion rates by up to 200%, and a better UX Design could raise it to 400%.” `,
      imageLoc: forresterLogo,
      imageAlt: 'Forrester',
    }
  ];

  return (
    <>
      <NeuRow>
        {/*Desktop*/}
        <h2 className="neu-text-weight--heavy neu-text-md-left pb-md-6 mb-md-6 d-none d-md-block">
          The Impact of UX According to Others
        </h2>
        {/*Mobile*/}
        <h5 className="neu-text-center d-md-none">
          The Impact of UX According to Others
        </h5>
      </NeuRow>
      <NeuRow className="d-flex justify-content-around">
        {theImpactData.map((card, key) => {
          return (
            <NeuCol
              key={key}
              sm={12}
              md={6}
              className="d-flex justify-content-right"
            >
              <CardNoButton
                quoteText={card.quoteText}
                imageLoc={card.imageLoc}
                imageAlt={card.imageAlt}
              />
            </NeuCol>
          );
        })}
      </NeuRow>
      <NeuRow className="m-md-6 p-md-6">
        <NeuCol className="d-flex justify-content-right"></NeuCol>
      </NeuRow>
    </>
  );
};
