import { NeuButton, NeuCol, NeuImage, NeuRow } from '@neutron/react';
import checklistOnPhone from '../../assets/images/accessibility/checklist-on-phone.svg';
import blueDiamondPlus from '../../assets/images/accessibility/blue-diamond-plus.svg';
export const Checklist = () => {
  return (
    <NeuRow className="d-flex flex-column-reverse flex-md-row justify-content-center align-items-center my-6 pt-6">
      <NeuCol className="my-6" sm={12} md={5}>
        {/*Desktop*/}
        <h3 className='d-none d-md-block mb-5'>Web Content Accessibility Guideline (WCAG) Checklist</h3>
        {/*Mobile*/}
        <h5 className='d-md-none mb-5 neu-text-center'>Web Content Accessibility Guideline (WCAG) Checklist</h5>
        <NeuButton className="d-none d-md-block align-self-start" href='https://connect.medcity.net/documents/365907235/0/wcag-21-checklist.pdf/845c7b87-1807-6dc7-8eb2-b74b091e01a6?version=1.0&t=1617123869043&download=true' target='_blank'>Download Checklist</NeuButton>
        
        <NeuImage
          className="large-diamond d-flex"
          alt="blue diamond plus mark"
          src={blueDiamondPlus}
          fluid
        />
        <NeuImage
          className="small-diamond d-flex"
          alt="blue diamond plus mark"
          src={blueDiamondPlus}
          fluid
        />
        <NeuButton className="d-md-none align-self-center" full>Download Checklist</NeuButton>
      </NeuCol>
      <NeuCol className="mb-6 mb-md-0" sm={12} md={5}>
        <NeuImage className="d-flex" alt="checklist on smart phone" src={checklistOnPhone} fluid />
      </NeuCol>
    </NeuRow>
  );
};
