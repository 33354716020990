import { NeuRow } from '@neutron/react';
import { ColleagueCard } from './ColleagueCard';
import { colleagueData } from './ColleagueData';

export const GetToKnow = (): JSX.Element => {
  return (
    <>
      {/*Desktop*/}
      <h2 className="d-none d-md-block neu-text-weight--heavy mb-md-6 pb-md-6">
        Get to Know the Team
      </h2>
      {/*Mobile*/}
      <h5
        className="d-md-none mb-6"
      >
        Get to Know the Team
      </h5>
      <NeuRow>
        {colleagueData.map((colleague, key) => {
          return (
            <ColleagueCard
              key={key}
              imageLoc={colleague.imageLoc}
              imageAlt={colleague.imageAlt}
              colleagueName={colleague.colleagueName}
              colleagueTitle={colleague.colleagueTitle}
              colleagueInfo={colleague.colleagueInfo}
              linkedInHyperLink={colleague.linkedInHyperLink}
            />
          );
        })}
      </NeuRow>
    </>
  );
};
