import '../Uxcoe.scss';
import { ColleagueQuote } from '../utility-components/ColleagueQuote';

import bradCircleImage from '../../assets/images/testimonials/brad-circle.png';
import gabeCircleImage from '../../assets/images/testimonials/gabe-circle.png';
import nicoleCircleImage from '../../assets/images/testimonials/nicole-circle.png';

export const Testimonials = (): JSX.Element => {
  let colleaguesData = [
    {
      imageLoc: gabeCircleImage,
      imageAlt: 'Gabriel Perez',
      quoteText: "API's connect systems, User Experience connects people.",
      name: 'Gabe Perez',
      title: 'Vice President',
      org: 'Software Development Organization'
    },
    {
      imageLoc: bradCircleImage,
      imageAlt: 'Brad Cayer',
      quoteText:
        "I've enjoyed working with the UI/UX team and how quickly they've been able to bring ideas and experiences to life. They're great to work with, and I couldn't speak more highly of the team.",
      name: 'Brad Cayer',
      title: 'Director of Application Development',
      org: 'at HCA, South Atlantic Division'
    },
    {
      imageLoc: nicoleCircleImage,
      imageAlt: 'Nicole Tremblett',
      quoteText: 'User experience is the glue that holds ITG together.',
      name: 'Nicole Tremblett',
      title: 'VP IS&O',
      org: 'Shared Services and Nursing Technology'
    }
  ];

  return (
    <section>
      <div className="mt-5">
        {/*Desktop*/}
        <h2 className="neu-text-weight--heavy neu-text-md-left mb-md-6 d-none d-md-block">
          Testimonials About the UXCOE
        </h2>
        {/*Mobile*/}
        <h5 className="neu-text-weight--bold neu-text-center my-3 mb-3 d-md-none">
          Testimonials About the UXCOE
        </h5>
      </div>
      <div className="d-flex flex-column">
        {colleaguesData.map((colleague, key) => {
          return (
            <ColleagueQuote
              key={key}
              imageLoc={colleague.imageLoc}
              imageAlt={colleague.imageAlt}
              quoteText={colleague.quoteText}
              colleagueName={colleague.name}
              colleagueTitle={colleague.title}
              colleagueOrg={colleague.org}
            />
          );
        })}
      </div>
    </section>
  );
};
