import { Banner } from '../utility-components/Banner';
import meetTheTeamBannerImage from '../../assets/images/hero-images/meet-the-team.png';

export const TeamHeroBanner = (): JSX.Element => {
  const teamHeroBanner = {
    imageLoc: meetTheTeamBannerImage,
    fillColor: '#C45F33',
    bannerTitle: 'Meet the Team',
    bannerSubtitle: null,
    bannerText:
      'We aren’t just designers, we are community and work together as one.',
    bannerButtons: false
  };

  return (
    <Banner
      fillColor={teamHeroBanner.fillColor}
      imageLoc={teamHeroBanner.imageLoc}
      bannerTitle={teamHeroBanner.bannerTitle}
      bannerSubtitle={teamHeroBanner.bannerSubtitle}
      bannerText={teamHeroBanner.bannerText}
      bannerButtons={teamHeroBanner.bannerButtons}
    />
  );
};
