//These are components that get imported into the InfoBlock Component. 
//The Info block component acts as a standard container that these custom text layouts can be fed into.

export const PourComponentText = (): JSX.Element => {
  return (
    <section>
      {/*Desktop*/}
      <h4 className="d-none d-md-block">Remember P.O.U.R.</h4>
      {/*Mobile*/}
      <h6 className="d-md-none">Remember P.O.U.R.</h6>
      <ul className="lh list-pad">
        <li>
          <strong>Perceivable</strong> – all information is available through
          sight, hearing or touch
        </li>
        <li>
          <strong>Operable</strong> – compatible with keyboard (including
          technologies that emulate a keyboard) or mouse
        </li>
        <li>
          <strong>Understandable</strong> – content is consistent, user-friendly
          and easy to comprehend
        </li>
        <li>
          <strong>Robust</strong> – the product works perfectly across all
          browsers, devices and assistive technologies
        </li>
      </ul>
    </section>
  );
};

export const ADA003ComponentText = (): JSX.Element => {
  return (
    <section className="d-flex flex-column justify-content-around">
      <h5 className="d-md-none neu-text-weight--heavy neu-text-start my-6 py-6">
        At HCA Healthcare, we are dedicated to a Healthier Tomorrow for all.
      </h5>
      {/*Desktop*/}
      <h4 className="d-none d-md-block neu-text-weight--light">
        Web Accessibility Policy ADA-003
      </h4>
      {/*Mobile*/}
      <h3 className="d-md-none neu-text-weight--regular">
        Web Accessibility Policy ADA-003
      </h3>
      <div className="neu-text--body d-flex flex-column justify-content-around">
        {/*Desktop*/}
        <div className="d-none d-md-block">
          <p className="lh my-2">
            Legally, as of May 1st, 2018, all of our websites, apps, and mobile
            apps must meet WCAG (web content accessibility guidelines) Level AA
            compliance.
          </p>
          <p className="lh my-2">
            This law falls under Section 508 of the Americans with Disabilities
            Act. All new content, websites, or apps will not go public if it
            fails compliance with WCAG guidelines. Though we are more than
            legally dedicated– we go above and beyond to provide first-class
            care to our patients and colleagues.
          </p>
        </div>
        {/*Mobile*/}
        <div className="d-md-none">
          <p className="neu-text--caption neu-text-weight--regular lh my-6">
            Legally, as of May 1st, 2018, all of our websites, apps, and mobile
            apps must meet WCAG (web content accessibility guidelines) Level AA
            compliance.
          </p>
          <p className="neu-text--caption neu-text-weight--regular lh my-6">
            This law falls under Section 508 of the Americans with Disabilities
            Act. All new content, websites, or apps will not go public if it
            fails compliance with WCAG guidelines. Though we are more than
            legally dedicated– we go above and beyond to provide first-class
            care to our patients and colleagues.
          </p>
        </div>
      </div>
    </section>
  );
};

export const AccessibilityGroupComponentText = (): JSX.Element => {
  return (
    <p className="d-flex flex-column justify-content-around">
      <div className="d-none d-md-block neu-text--h5 neu-text-weight--regular">
        An excellent experience is accessible to humans of all abilities,
        including low vision, hearing, cognitive, or motor impairments.
      </div>
      <div className="d-none d-md-block neu-text--h5 neu-text-weight--regular">
        Develop the highest accessibility care to simultaneously strengthen
        usability for those with temporary obstacles and situational barriers.
      </div>
      <div className="d-md-none neu-text--support neu-text-weight--regular lh mt-4">
        An excellent experience is accessible to humans of all abilities,
        including low vision, hearing, cognitive, or motor impairments.
      </div>
      <div className="d-md-none neu-text--support neu-text-weight--regular lh mt-4">
        Develop the highest accessibility care to simultaneously strengthen
        usability for those with temporary obstacles and situational barriers.
      </div>
    </p>
  );
};
