import { NeuButton, NeuCard, NeuCol, NeuImage } from '@neutron/react';
import '../Uxcoe.scss';

interface AccessibilityCardProps {
  imageAlt: string;
  imageLoc: string;
  title: string;
  info: string;
  link: string;
  buttonText: string;
}

export const AccessibilityCard = ({
  imageAlt,
  imageLoc,
  title,
  info,
  link,
  buttonText
}: AccessibilityCardProps) => {
  return (
    <NeuCol className="d-flex flex-fill" sm={12} md={4}>
      <NeuCard
        className="d-flex flex-column align-items-center mx-0 my-5"
        width="fit-content"
      >
          <NeuImage
            alt={imageAlt}
            minHeight="20rem"
            minWidth="auto"
            src={imageLoc}
            className="d-flex"
          />
          <section className='flex-grow-1'>
            <h5 className="align-self-start">
              {title}
            </h5>
            <p className="neu-text-start neu-text--body mt-4">
              {info}
            </p>
          </section>
          <NeuButton className="mt-6" href={link} fill="outline" target='_blank'>
            {buttonText}
          </NeuButton>
      </NeuCard>
    </NeuCol>
  );
};
