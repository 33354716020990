import { NeuCol, NeuImage, NeuRow } from '@neutron/react';

interface InfoBlockProps {
  imageLoc?: string;
  imageAlt?: string;
  text: JSX.Element;
  rightSideImage?: boolean;
}

export const InfoBlock = ({
  imageLoc,
  text,
  rightSideImage,
  imageAlt
}: InfoBlockProps) => {
  return (
    <NeuRow
      className={`${
        rightSideImage ? 'flex-row-reverse' : ''
      } justify-content-around mb-6 pb-6`}
    >
      <NeuCol sm={12} md={5}>
        {imageLoc && imageAlt && <NeuImage className="d-flex" width="fit-content" alt={imageAlt} src={imageLoc} fluid />}   
      </NeuCol>
      <NeuCol className="d-flex justify-content-center" sm={12} md={5}>
        {text}
      </NeuCol>
    </NeuRow>
  );
};
