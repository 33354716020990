import { NeuImage } from '@neutron/react';

import threeStepLegos from '../../assets/images/neutron-page/legos/lego-3-step-2.png';

export const Legos = () => (
  <section className="d-flex flex-column align-items-center">
    {/*Desktop*/}
    <h2 className="neu-text-weight--heavy neu-text-md-left mb-6 d-none d-md-block align-self-start">
      Bring Ideas to Life Faster
    </h2>
    <h5 className="d-none d-md-block">
      Neutron components are like lego bricks. You can use the same pieces in
      varying arrangements to make creatively unique products.
    </h5>

    {/*Mobile*/}
    <h5 className="neu-text-center mb-3 d-md-none">
      Bring Ideas to Life Faster
    </h5>
    <div className="d-md-none neu-text--body neu-text-weight--bold neu-text-center">
      Neutron components are like lego bricks. You can use the same pieces in
      varying arrangements to make creatively unique products.
    </div>
    <NeuImage
      alt="lego pieces collected by color"
      src={threeStepLegos}
      maxWidth="100%"
      fluid
      className="d-flex my-6"
    />
  </section>
);


