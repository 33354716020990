import { NeuCol, NeuImage, NeuRow } from '@neutron/react';

interface Colleague {
  imageLoc: string;
  imageAlt: string;
  colleagueName: string;
  colleagueTitle: string;
  colleagueInfo: string;
  linkedInHyperLink: string;
}

interface SquadSectionProps {
  name: string;
  description: string;
  pictures: Array<Colleague>;
}

export const SquadSection = ({
  name,
  description,
  pictures
}: SquadSectionProps) => {
  return (
    <NeuRow className="my-6 py-6 d-flex flex-column-reverse flex-md-row">
      <NeuCol md={7} className="d-flex flex-column vh-80">
        {/*Desktop*/}
        <h3 className="d-none d-md-block neu-text-weight--light mb-md-3 squad-title">{name}</h3>
        <h4 className="d-none d-md-block w-90 mb-1 mb-md-3 neu-text-weight--light">{description}</h4>
        {/*Mobile*/}
        <h6 className="d-md-none mb-1">{name}</h6>
        <p className="d-md-none neu-text--caption neu-text-weight--regular w-90 mb-1 mb-md-3">{description}</p>
      </NeuCol>
      <NeuCol class='d-flex flex-row flex-wrap mb-6 mb-md-1' cols={10} md={5}>
        {pictures.map((pic, key) => {
          return <NeuImage alt={pic.imageAlt} className="d-flex" fluid key={key} maxHeight='6rem' maxWidth='6rem' src={pic.imageLoc}/>;
        })}
      </NeuCol>
    </NeuRow>
  );
};
