import '../Uxcoe.scss';
import { NeuCol, NeuRow } from '@neutron/react';
import { PortraitImageCard } from '../utility-components/PortraitImageCard';
import '../Uxcoe.scss';

import C4CImage from '../../assets/images/ourimpact/C4C.png'
import neutronImage from '../../assets/images/ourimpact/NeutronDS.png'
import careersImage from '../../assets/images/ourimpact/HCACareers.png'

export const Achievements = (): JSX.Element => {
  let achievementCardData = [
    {
      imageLoc: C4CImage,
      imageAlt: 'coding for caregivers',
      contentTitle: 'Coding For Caregivers',
      content:
        'With our nurses top of mind, we thank you to all the hands that participated in making this event a success and have such a great outcome.',
      buttonText: 'Learn more about C4C',
      link: 'https://connect.medcity.net/web/cfc-tde/home'
    },
    {
      imageLoc: neutronImage,
      imageAlt: 'Neutron Design System',
      contentTitle: 'Neutron Design System',
      content:
        'Neutron is the official digital design system built to support the HCA Healthcare enterprise brand strategy.',
      buttonText: 'More info on Neutron',
      link: 'https://hcadmx.invisionapp.com/dsm/hcadmx/neutron?mode=preview'
    },
    {
      imageLoc: careersImage,
      imageAlt: 'HCA Careers',
      contentTitle: 'HCA Careers',
      content:
        'Driven by a single mission: Above all else, we are committed to caring for and improving human life.',
      buttonText: 'More info on HCA Careers',
      link: 'https://careers.hcahealthcare.com/'
    }
  ];

  return (
    <>
      <NeuRow>
        <NeuCol className='justify-content-center'>
          {/*Desktop*/}
          <h2 className="neu-text-weight--heavy neu-text-md-left mb-md-6 d-none d-md-block">
            UXCOE’s Achievements and Partnerships
          </h2>
          {/*Mobile*/}
          <h5 className="neu-text-weight--heavy neu-text-center mx-1 mb-md-6 d-md-none">
            Achievements & Partnerships
          </h5>
        </NeuCol>
      </NeuRow>
      <NeuRow className="d-flex justify-content-around">
        {achievementCardData.map((card, key) => {
          return (
            <NeuCol
              key={key}
              sm={12}
              md={3}
              className="d-flex justify-content-center"
            >
              <PortraitImageCard
                imageLoc={card.imageLoc}
                imageAlt={card.imageAlt}
                contentTitle={card.contentTitle}
                content={card.content}
                buttonText={card.buttonText}
                raised={true}
                iconSlot={false}
                downloadLink={card.link}
              />
            </NeuCol>
          );
        })}
      </NeuRow>
    </>
  );
};
