import { NeuImage } from '@neutron/react';

type SharedServicesComponentProps = {
  imageLoc: string;
  imageAlt: string;
  title: string;
  slug: string[];
};
//This is used in SharedServices.tsx
export const SharedServicesComponent = ({
  imageLoc,
  imageAlt,
  title,
  slug
}: SharedServicesComponentProps): JSX.Element => (
  <div className="d-flex flex-column align-items-center justify-content-between">
    <NeuImage
      className="d-flex"
      src={imageLoc}
      alt={imageAlt}
      maxWidth="100%"
      minHeight="9rem"
      height="auto"
      fluid
    />
    <p className="my-4 neu-text-center neu-text--h5">{title}</p>
    <ul className='justify-content-center'>
      {slug.map(listItem => {
        return <p className="neu-text-center">{listItem}</p>;
      })}
    </ul>
  </div>
);
