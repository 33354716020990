import { NeuCol, NeuContainer, NeuRow } from '@neutron/react';
import { TeamHeroBanner } from './TeamHeroBanner';
import { Footer } from '../footer/Footer';
import { ConnectForm } from '../utility-components/ConnectForm';
import { GetToKnow } from './GetToKnow';
import { SquadBreakdown } from './SquadBreakdown';

export const MeetTheTeam = (): JSX.Element => {
  return (
    <NeuContainer fluid>
      <NeuRow>
        <NeuCol>
          <TeamHeroBanner />
        </NeuCol>
      </NeuRow>
      <NeuRow className="justify-content-center my-6">
        <NeuCol sm={12} md={10}>
          <SquadBreakdown />
        </NeuCol>
      </NeuRow>
      <NeuRow className="justify-content-center my-6">
        <NeuCol sm={12} md={10}>
          <GetToKnow />
        </NeuCol>
      </NeuRow>
      <NeuRow className="justify-content-center my-6">
        <NeuCol sm={12} md={10}>
          <ConnectForm />
        </NeuCol>
      </NeuRow>
      <NeuRow>
        <Footer />
      </NeuRow>
    </NeuContainer>
  );
};
