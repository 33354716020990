import officeCheckIn from '../../assets/images/accessibility/office-check-in.svg';
import womanInWheelChair from '../../assets/images/accessibility/woman-in-wheelchair.svg';
import { ADA003ComponentText, PourComponentText } from './BlockTextComponents';
import { InfoBlock } from './InfoBlock';

export const HealthierTomorrow = () => {
  return (
    <>
      <h2 className="d-none d-md-block neu-text-weight--heavy my-6 py-6">
        At HCA Healthcare, we are dedicated to a Healthier Tomorrow for all.
      </h2>
      <div className="mb-6">
        <InfoBlock
          imageLoc={womanInWheelChair}
          text={<ADA003ComponentText />}
          rightSideImage
          imageAlt={
            'Woman in wheelchair speaking with seated older man and mother with child.'
          }
        />
        <p className="d-md-none neu-text-left my-6 py-6 neu-text-weight--bold">
          Our Meaningful Work Achieves Excellence Through 4 Key Principles of
          Accessibility
        </p>
        <h3 className="d-none d-md-block neu-text-center my-6 py-6 neu-text-weight--light">
          Our Meaningful Work Achieves Excellence Through 4 Key Principles of
          Accessibility
        </h3>

        <InfoBlock
          imageLoc={officeCheckIn}
          text={<PourComponentText />}
          imageAlt={`blind man checking in at doctor's office. Woman in lab coat and another woman off to the side waiting to greet him.`}
        />
      </div>
    </>
  );
};
