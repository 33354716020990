import '../Uxcoe.scss';
import {
  NeuContainer,
  NeuCol,
  NeuFab,
  NeuFabButton,
  NeuIcon,
  NeuLabel,
  NeuRow
} from '@neutron/react';
import { Footer } from '../footer/Footer';
import { HeroBanner } from './HeroBanner';
import { StarterFileList } from './StarterFileList';
import { useState } from 'react';
import SearchBar from './SearchBar';
import { starterFilesCellData } from './CellData';
import { Cell } from '../../types/interfaces';
import { FilterBar } from './FilterBar';

interface StarterFileProp {
  scrollToTop: () => void;
}

export const StarterFiles = ({ scrollToTop }: StarterFileProp): JSX.Element => {
  //state variables for the search bar and the filter buttons
  const [searchQuery, setSearchQuery] = useState('');
  const [filterState, setFilterState] = useState({ level: 'all' });

  //filter the cells
  const filterCells = (cells: Cell[], filter: string) => {
    if (filter === 'all') {
      return cells;
    }
    //if the cell has an icon string location that includes our filter term then return that
    return cells.filter(cell => {
      return cell.iconLoc.includes(filter);
    });
  };
  //filtered cells is starting with all the cell data and we are passing in our state variable
  const filteredCells = filterCells(starterFilesCellData, filterState.level);

  //search the cells
  const searchCells = (cells: Cell[], query: string) => {
    return cells.filter((cell: any) => {
      //we're searching based on the cell title. Making it lowercase to make it easier to match results
      const cellTitle = cell.title.toLowerCase();
      //we return any titles that include the query string (it's also been made lowercase)
      return cellTitle.includes(query.toLowerCase());
    });
  };
  // feed the result of filteredCells() into searchCells and pass in our state variable
  const searchedAndFilteredCells = searchCells(filteredCells, searchQuery);

  return (
    <NeuContainer fluid>
      <NeuRow>
        <NeuCol>
          <HeroBanner />
        </NeuCol>
      </NeuRow>
      <NeuRow className="justify-content-center my-6">
        <NeuCol sm={12} md={10}>
          <NeuRow className="justify-content-baseline mb-md-6">
            <NeuCol sm={12} md={6} className="d-none d-md-block">
              <FilterBar
                filterState={filterState}
                setFilterState={setFilterState}
              />
            </NeuCol>
            <NeuCol sm={12} md={6} className="d-none d-md-block">
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </NeuCol>
          </NeuRow>
          <StarterFileList
            scrollToTop={scrollToTop}
            cells={searchedAndFilteredCells}
          />
        </NeuCol>
      </NeuRow>
      <NeuRow>
        <NeuCol className="d-none d-md-block">
          <NeuFab horizontal="end" vertical="bottom">
            <NeuFabButton onClick={() => scrollToTop()} color="primary">
              <NeuIcon btn>arrow_upward</NeuIcon>
              <NeuLabel>Top</NeuLabel>
            </NeuFabButton>
          </NeuFab>
        </NeuCol>
      </NeuRow>
      <NeuRow>
        <Footer />
      </NeuRow>
    </NeuContainer>
  );
};
