import {
  NeuButton,
  NeuCard,
  NeuCardContent,
  NeuCol,
  NeuImage,
  NeuRow
} from '@neutron/react';
import furtherInfoImage from '../../assets/images/neutron-page/further-info-image.png';
import neutronLogo from '../../assets/images/neutron-page/Neutron-fullcolor-horizontal.png';

export const FurtherInfo = () => {
  /*The content for this component has been broken out into mobile and desktop components. 
  When the page loads, Javascript queries the DOM for the screen size and loads the appropriate component for that size. */
  const smallScreenSize = window.matchMedia('(max-width: 767.98px)');
  return (
    <>
    {/*Desktop*/}
      <h2 className="neu-text-weight--heavy d-none d-md-block">
        For Further Info
      </h2>
      {/*Mobile*/}
      <h5 className="neu-text-left mb-3 d-md-none mb-6">For Further Info</h5>
      {/*Conditional Loading based on scren size*/}
      {smallScreenSize.matches ? <InfoMobile/> : <InfoDesktop/>}
    </>
  );
};

const InfoDesktop = () => {
  return (
    <NeuCard className="p-0" height="fit-content">
      <NeuCardContent className="p-0">
        <NeuRow className="d-flex justify-content-between align-items-center">
          <NeuCol className="ml-6 pl-6 d-flex flex-column justify-content-around" sm={12} md={5}>
            <NeuImage
              className="d-flex"
              alt="Neutron Design System"
              src={neutronLogo}
            />
            <h6 className="neu-text-weight--heavy my-5">
              Design System Management Site (DSM)
            </h6>
            <p className="mb-6">
              Neutron was created and built by our UXCOE team here at HCA
              Healthcare. Neutron DSM site is where you can find more detailed
              documentation about the system.{' '}
            </p>
            <NeuButton
              className="my-6"
              color="primary"
              href="https://hcadmx.invisionapp.com/dsm/hcadmx/neutron"
              target='"_blank"'
              type="button"
              fill="raised"
            >
              Dive into the Neutron DSM
            </NeuButton>
          </NeuCol>
          <NeuCol className="d-flex justify-content-end pr-0" sm={0} md={5}>
            <NeuImage
              className="d-flex"
              alt="several Neutron designed web pages placed loosely together"
              src={furtherInfoImage}
            ></NeuImage>
          </NeuCol>
        </NeuRow>
      </NeuCardContent>
    </NeuCard>
  );
};

const InfoMobile = () => {
  return (
    <NeuRow>
      <NeuCol className="" sm={12} md={5}>
        <NeuImage
          className="d-flex pb-6 mb-6"
          alt="Neutron Design System logo"
          src={neutronLogo}
        />
        <div className="neu-text-weight--bold neu-text--body neu-text-center mb-5">
          Design System Management Site (DSM)
        </div>
        <p className="neu-text--caption neu-text-weight--regular mb-6 mx-6 px-3">
          Neutron was created and built by our UXCOE team here at HCA
          Healthcare. Neutron DSM site is where you can find more detailed
          documentation about the system.{' '}
        </p>
        <NeuButton
          className="my-6"
          color="primary"
          href="https://hcadmx.invisionapp.com/dsm/hcadmx/neutron"
          target='"_blank"'
          type="button"
          fill="raised"
          full
        >
          Dive into the Neutron DSM
        </NeuButton>
      </NeuCol>
    </NeuRow>
  );
};
