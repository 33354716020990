import "../Uxcoe.scss";
import { ColleagueQuote } from "../utility-components/ColleagueQuote";

import teresaCircleImage from "../../assets/images/neutron-page/colleagues/teresa-lawson-circle-image.png"
import paigeCircleImage from "../../assets/images/neutron-page/colleagues/paige-simpson-circle-image.png"

export const Testimonials = (): JSX.Element => {
  let colleaguesData = [
    {
      imageLoc: teresaCircleImage,
      imageAlt: "Teresa Lawson",
      quoteText: "Thanks for steering us in the right direction regarding bot options. You were so helpful. Appreciate you!”",
      name: "Teresa Lawson",
      title: "Program Manager",
      org: "ITG Field Operations Administration",
    },
    {
      imageLoc: paigeCircleImage,
      imageAlt: "Paige Simpson",
      quoteText:
        "Everyone on the team is super professional and great at what they do and do a GREAT job to help out even in the tight timeline.”",
      name: "Paige Simpson",
      title: "Sr Product Analyst Clinical",
      org: "Development and Innovation",
    },
  ];

  return (
    <section>
      <div className="mt-5">
        {/*Desktop*/}
        <h2 className="neu-text-weight--heavy neu-text-md-left mb-6 d-none d-md-block">
          Neutron Testimonials
        </h2>
        {/*Mobile*/}
        <p className="neu-text-weight--heavy neu-text--h5 neu-text-center my-3 d-md-none">
          Neutron Testimonials
        </p>
      </div>
      <div className="d-flex flex-column">
        {colleaguesData.map((colleague, key) => {
          return (
            <ColleagueQuote
              key={key}
              imageLoc={colleague.imageLoc}
              imageAlt={colleague.imageAlt}
              quoteText={colleague.quoteText}
              colleagueName={colleague.name}
              colleagueTitle={colleague.title}
              colleagueOrg={colleague.org}
            />
          );
        })}
      </div>
    </section>
  );
};
