import '../Uxcoe.scss'
import { NeuButton, NeuCol, NeuImage, NeuRow } from '@neutron/react';
import trainingDays from '../../assets/images/neutron-page/training-days.svg'
export const TrainingDays = () => {
const trainingDaysLink = "https://www.healthstream.com/HSAPP/CoursePreEnrollment?courseId=0e29f9ba-b610-ec11-80e7-005056b10484&courseVersion=1"
    return (
        <>
        <NeuRow className="d-flex flex-row align-items-center justify-content-around">
            <NeuCol md={5} cols={12}>
                <NeuImage className="d-flex" alt="a stack of books" src={trainingDays} fluid/>
            </NeuCol>
            <NeuCol sm={12} md={5} cols={12} className="d-flex flex-column align-self-stretch align-items-center align-items-md-start justify-content-md-around">
                {/*Desktop*/}
                <h3 className='d-none d-md-block neu-text-weight--bold neu-text-md-left neu-text-center'>Check out our IT Training Days Class “Intro to Neutron”</h3>
                {/*Mobile*/}
                <h5  className='my-6 d-md-none neu-text-md-left neu-text-center'>Check out our IT Training Days Class “Intro to Neutron”</h5>
                <NeuButton href={trainingDaysLink} target='_blank'>Watch the Course on Healthstream</NeuButton>
            </NeuCol>
        </NeuRow>
        </>
    )
}