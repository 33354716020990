import '../Uxcoe.scss';
import {
  NeuCardTitle,
  NeuCol,
  NeuRow,
  NeuInput,
  NeuItem,
  NeuTextarea,
  NeuButton,
  NeuCard,
  NeuCardContent,
  NeuCardActions,
  NeuLabel
} from '@neutron/react';
import { useState } from 'react';

export const ConnectForm = (): JSX.Element => {
  //set up a state object to hold values for the form
  let emptyState = {
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  };

  //set up a state variable to manage the the change in form values
  const [values, setValues] = useState(emptyState);

  //add items to the state object
  const handleInputChange = (event: any) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  //turn the object into a urlstring and send that to the Azure Function.
  //Get a response and clear the form
  const sendEmail = (event: any) => {
    const base = `https://uxcoe-mail-relay.azurewebsites.net/api/uxcoe-mail-relay`;
    const query = `?firstname=${values.firstName}&lastname=${values.lastName}&email=${values.email}&message=${values.message}`;
    const url = base + query;

    event.preventDefault();
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(values)
    }).then(res => {
      console.log(res);
      setValues(emptyState);
    });
  };

  return (
    <NeuCard className="m-0" id="connect-form" color="plain-0">
      <NeuCardTitle color="plain-100">
        {/* This component is used a few different places. Render the title based on the the url path */}
        <h2 className="neu-text-weight--heavy d-none d-md-block">
          Connect With Us
        </h2>
        <h4 className="neu-text-weight--bold d-md-none">Connect With Us</h4>
      </NeuCardTitle>
      <NeuCardContent>
        <NeuRow>
          <NeuCol md="6" cols="12" className="neu-text-md-left">
            <NeuItem>
              <NeuLabel content={'First Name'} />
              <NeuInput
                inputMode="text"
                required
                name="firstName"
                value={values.firstName}
                onNeuChange={handleInputChange}
              />
            </NeuItem>
          </NeuCol>
          <NeuCol md="6" cols="12" className="neu-text-md-left">
            <NeuItem>
              <NeuLabel content={'Last Name'} />
              <NeuInput
                inputMode="text"
                required
                name="lastName"
                value={values.lastName}
                onNeuChange={handleInputChange}
              />
            </NeuItem>
          </NeuCol>
        </NeuRow>
        <NeuItem>
          <NeuLabel content={'Email'} />
          <NeuInput
            inputMode="email"
            required
            name="email"
            value={values.email}
            onNeuChange={handleInputChange}
          />
        </NeuItem>
        <NeuItem>
          <NeuLabel content={'Message'} />
          <NeuTextarea
            inputMode="text"
            required
            name="message"
            value={values.message}
            onNeuChange={handleInputChange}
          />
        </NeuItem>
      </NeuCardContent>
      <NeuCardActions>
        <NeuButton fill="raised" color="primary" onClick={sendEmail}>
          Submit Comment
        </NeuButton>
      </NeuCardActions>
    </NeuCard>
  );
};
