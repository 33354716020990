import { NeuCol, NeuRow } from '@neutron/react';
import { CallOutBox } from '../utility-components/CallOutBox';

import blogIcon from '../../assets/images/call-out-box-icons/blog.svg';
import mouseClickIcon from '../../assets/images/call-out-box-icons/mouse-click.svg';
import NeutronIcon from '../../assets/images/call-out-box-icons/neutron-shapes.svg';
import processIcon from '../../assets/images/call-out-box-icons/process.svg';
import templatesIcon from '../../assets/images/call-out-box-icons/templates.svg';
import bookDownloadIcon from '../../assets/images/call-out-box-icons/book-download.svg';

export const GetStarted = (): JSX.Element => {
  let callOutData = [
    {
      imageLoc: templatesIcon,
      imageAlt: 'website skeleton',
      callOutText: 'Download Our Templates',
      route: '/get-started'
    },
    {
      imageLoc: processIcon,
      imageAlt: 'relationship diagram',
      callOutText: 'See Our Impact',
      route: '/our-impact'
    },
    {
      imageLoc: mouseClickIcon,
      imageAlt: 'mouse cursor clicking',
      callOutText: 'Test with Us',
      link: 'https://forms.office.com/r/ShrtMGuu0E'
    },
    {
      imageLoc: NeutronIcon,
      imageAlt: 'triangle, circle and square',
      callOutText: 'Our Design System Neutron',
      route: '/neutron-design-system'
    },
    {
      imageLoc: blogIcon,
      imageAlt: 'pencil writing on paper',
      callOutText: 'Read Our Blogs',
      route: '/blogs'
    },
    {
      imageLoc: bookDownloadIcon,
      imageAlt: 'open book with orange down arrow coming down the middle',
      callOutText: 'Get The UX Playbook',
      link: 'https://connect.medcity.net/documents/365907235/0/UXCOE+Playbook.pdf/9c255f5b-bbaa-5eec-05e8-e7d6b3c688e5?t=1637348209562'
    }
  ];
  return (
    <>
      {/*Desktop*/}
      <h2 className="neu-text-weight--heavy my-6 d-none d-md-block">
        Get started with UX
      </h2>
      {/*Mobile*/}
      <h5 className="neu-text-weight--bold neu-text-center my-3 mb-3 d-md-none">
        Get Started with UX
      </h5>
      <NeuRow className="d-flex flex-column flex-md-row">
        {callOutData.map((callOut, key) => {
          return (
            <NeuCol key={key} sm={12} md={6} cols={12}>
              <CallOutBox
                imageLoc={callOut.imageLoc}
                imageAlt={callOut.imageAlt}
                callOutText={callOut.callOutText}
                link={callOut.link}
                route={{ pathname: callOut.route }}
              />
            </NeuCol>
          );
        })}
      </NeuRow>
    </>
  );
};
