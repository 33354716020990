import a11yHandbook from '../../assets/images/accessibility/resources/a11y-handbook.svg';
import axeExtension from '../../assets/images/accessibility/resources/axe-extension.svg';
import checklist from '../../assets/images/accessibility/resources/checklist.svg';
import colorChecker from '../../assets/images/accessibility/resources/color-checker.png';
import disabilities from '../../assets/images/accessibility/resources/disabilities-vs-web.svg';
import frontEnd from '../../assets/images/accessibility/resources/front-end.svg';
import mobile from '../../assets/images/accessibility/resources/mobile.svg';
import wcagStandards from '../../assets/images/accessibility/resources/wcag-standards.svg';
import web from '../../assets/images/accessibility/resources/web.svg';
import { AccessibilityCard } from './AccessibilityCard';
import { NeuRow } from '@neutron/react';

export const AccessibilityResources = () => {
  const accessibilityCardData = [
    {
      imageLoc: web,
      imageAlt: 'computer monitor and mouse',
      title: 'Web Accessibility In Mind',
      info: 'WebAIM has provided comprehensive web accessibility solutions since 1999. WebAIM is one of the leading providers of web accessibility internationally.',
      link: 'https://webaim.org/articles/',
      buttonText: 'Discover web accessibility'
    },
    {
      imageLoc: wcagStandards,
      imageAlt: 'laptop with handicap symbol and code brackets',
      title: 'WCAG Standards',
      info: 'The Web Content Accessibility Guidelines (WCAG) is the Internationally recognized and adopted standards for making content and applications more accessible to all.',
      link: 'https://www.w3.org/WAI/WCAG21/quickref/',
      buttonText: 'Discover WCAG Standards'
    },
    {
      imageLoc: checklist,
      imageAlt: 'hands holding mobile phone with checklist',
      title: 'WAI - ARIA Cheatsheet',
      info: 'Web Accessibility Initiative (WAI) Accessible Rich Internet Application (ARIA) kit helps dynamic HTML, javascript, and related technologies more accessible for all.',
      link: 'https://lab.abhinayrathore.com/aria-cheatsheet/',
      buttonText: 'Discover WAI-ARIA Cheatsheet'
    },
    {
      imageLoc: a11yHandbook,
      imageAlt: 'stack of books',
      title: 'A11y Handbook (IBM)',
      info: 'A11y is an intentional shorthand for accessibility and a movement meant to convey that we are allies. This Handbook is for developers, and it identifies conditions, both physical and cognitive.',
      link: 'https://accessibility-handbook.mybluemix.net/design/a11y-handbook/',
      buttonText: 'Discover A11y Handbook'
    },
    {
      imageLoc: mobile,
      imageAlt: 'hand holding mobile phone looking at text thread',
      title: 'Accessibility for Mobile',
      info: 'Guidelines categorize the best practices that can easily be tested with specific criteria that are not subjective.',
      link: 'https://www.bbc.co.uk/accessibility/forproducts/guides/mobile/summary/',
      buttonText: 'Discover accessibility for mobile'
    },
    {
      imageLoc: frontEnd,
      imageAlt: 'desk with books and laptop',
      title: 'Front-End Components',
      info: 'In a new short series of posts, we highlight some valuable tools and techniques for developers and designers.',
      link: 'https://www.smashingmagazine.com/2021/03/complete-guide-accessible-front-end-components/',
      buttonText: 'Discover front end components'
    },
    {
      imageLoc: disabilities,
      imageAlt:
        'woman sitting at desk wearing hijab, speaking to man sitting in chair',
      title: 'Disabilities vs. Web',
      info: 'This resource introduces how people with disabilities, including people with age-related impairments, use the Web. ',
      link: 'https://www.tpgi.com/color-contrast-checker/',
      buttonText: 'Discover disabilities vs. web'
    },
    {
      imageLoc: colorChecker,
      imageAlt: 'abstract image of colors',
      title: 'Color Checker',
      info: 'A free color contrast checker tool allows you to quickly determine the contrast ratio of two colors simply using an eyedrop tool. ',
      link: 'https://www.tpgi.com/color-contrast-checker/',
      buttonText: 'Discover color checker'
    },
    {
      imageLoc: axeExtension,
      imageAlt: '',
      title: 'Axe Browser Extension ',
      info: 'Chosen by Microsoft, Google, development and testing teams everywhere, axe is the World’s leading digital accessibility toolkit.',
      link: 'https://www.deque.com/axe/',
      buttonText: 'Discover axe browser extension'
    }
  ];

  return (
    <NeuRow>
      {accessibilityCardData.map((card, key) => {
        return (
            <AccessibilityCard
              key={key}
              imageAlt={card.imageAlt}
              imageLoc={card.imageLoc}
              title={card.title}
              info={card.info}
              link={card.link}
              buttonText={card.buttonText}
            />
        );
      })}
    </NeuRow>
  );
};
