import '../Uxcoe.scss';
import { NeuRow } from '@neutron/react';
import { BlogCard } from './BlogCard';
import '../Uxcoe.scss';
import LearningEndtoEnd from '../../assets/images/blogs/LearningEndtoEnd.png';
import Constraints from '../../assets/images/blogs/Constraints.png';
import MissedUXCOE from '../../assets/images/blogs/MissedUXCOE.png';
import ITTrainingDaysBlog from '../../assets/images/blogs/ITTrainingDaysBlog.png';
import NeutronDevDesigner from '../../assets/images/blogs/NeutronDevDesigner.png';
import MyInternship from '../../assets/images/blogs/MyInternship.png';
import UXCOEC4C from '../../assets/images/blogs/UXCOEC4C.png';
import SketchTips from '../../assets/images/blogs/SketchTips.png';
import NeutronIn2020 from '../../assets/images/blogs/NeutronIn2020.png';

export const BlogPosts = (): JSX.Element => {
  let BlogPostCardData = [
    {
      imageLoc: LearningEndtoEnd,
      imageAlt: 'Person walking on blue crosswalk',
      author: 'Emmie Roberts',
      readTime: '5 Mins Read',
      contentTitle: 'Learning End to End UX as a Novice',
      link: 'https://connect.medcity.net/web/uxcoe/overview/-/blogs/learning-end-to-end-ux-as-a-novice?_com_liferay_blogs_web_portlet_BlogsPortlet_redirect=https%3A%2F%2Fconnect.medcity.net%2Fweb%2Fuxcoe%2Foverview%3Fp_p_id%3Dcom_liferay_blogs_web_portlet_BlogsPortlet%26p_p_lifecycle%3D0%26p_p_state%3Dnormal%26p_p_mode%3Dview%26_com_liferay_blogs_web_portlet_BlogsPortlet_cur%3D1%26_com_liferay_blogs_web_portlet_BlogsPortlet_delta%3D20'
    },
    {
      imageLoc: Constraints,
      imageAlt: 'Man with arms spread out wide',
      author: 'Cydney Marsh',
      readTime: '4 Mins Read',
      contentTitle:
        'Constraints: The secret ingredient to designing successful user experiences',
      link: 'https://connect.medcity.net/web/uxcoe/overview/-/blogs/learning-end-to-end-ux-as-a-novice?_com_liferay_blogs_web_portlet_BlogsPortlet_redirect=https%3A%2F%2Fconnect.medcity.net%2Fweb%2Fuxcoe%2Foverview%3Fp_p_id%3Dcom_liferay_blogs_web_portlet_BlogsPortlet%26p_p_lifecycle%3D0%26p_p_state%3Dnormal%26p_p_mode%3Dview%26_com_liferay_blogs_web_portlet_BlogsPortlet_cur%3D1%26_com_liferay_blogs_web_portlet_BlogsPortlet_delta%3D20'
    },
    {
      imageLoc: MissedUXCOE,
      imageAlt: 'Man with shocked expression looking at computer',
      author: 'Amir Fathi',
      readTime: '2 Mins Read',
      contentTitle: 'Missed UXCoE Awesomeness?',
      link: 'https://connect.medcity.net/web/uxcoe/overview/-/blogs/2021-it-training-days-recap?_com_liferay_blogs_web_portlet_BlogsPortlet_redirect=https%3A%2F%2Fconnect.medcity.net%2Fweb%2Fuxcoe%2Foverview%3Fp_p_id%3Dcom_liferay_blogs_web_portlet_BlogsPortlet%26p_p_lifecycle%3D0%26p_p_state%3Dnormal%26p_p_mode%3Dview%26_com_liferay_blogs_web_portlet_BlogsPortlet_cur%3D1%26_com_liferay_blogs_web_portlet_BlogsPortlet_delta%3D20'
    },
    {
      imageLoc: ITTrainingDaysBlog,
      imageAlt: 'HCA Inspire',
      author: 'Emmie Roberts',
      readTime: '3 Mins Read',
      contentTitle: 'IT Training Days UX and Product Design Track',
      link: 'https://connect.medcity.net/web/uxcoe/overview/-/blogs/it-training-days-ux-and-product-design-track?_com_liferay_blogs_web_portlet_BlogsPortlet_redirect=https%3A%2F%2Fconnect.medcity.net%2Fweb%2Fuxcoe%2Foverview%3Fp_p_id%3Dcom_liferay_blogs_web_portlet_BlogsPortlet%26p_p_lifecycle%3D0%26p_p_state%3Dnormal%26p_p_mode%3Dview%26_com_liferay_blogs_web_portlet_BlogsPortlet_cur%3D1%26_com_liferay_blogs_web_portlet_BlogsPortlet_delta%3D20'
    },
    {
      imageLoc: NeutronDevDesigner,
      imageAlt: 'HCA Inspire',
      author: 'Cydney Marsh',
      readTime: '10 Mins Read',
      contentTitle: 'Neutron Developer & Designer Collaboration',
      link: 'https://connect.medcity.net/web/uxcoe/overview/-/blogs/neutron-design-development-consistency?_com_liferay_blogs_web_portlet_BlogsPortlet_redirect=https%3A%2F%2Fconnect.medcity.net%2Fweb%2Fuxcoe%2Foverview%3Fp_p_id%3Dcom_liferay_blogs_web_portlet_BlogsPortlet%26p_p_lifecycle%3D0%26p_p_state%3Dnormal%26p_p_mode%3Dview%26_com_liferay_blogs_web_portlet_BlogsPortlet_cur%3D1%26_com_liferay_blogs_web_portlet_BlogsPortlet_delta%3D20'
    },
    {
      imageLoc: MyInternship,
      imageAlt: 'Woman smiling in front of green hedge',
      author: 'Emma Roberts',
      readTime: '3 Mins Read',
      contentTitle: 'My Internship Experience with the UXCOE',
      link: 'https://connect.medcity.net/web/uxcoe/overview/-/blogs/my-experience-with-uxcoe?_com_liferay_blogs_web_portlet_BlogsPortlet_redirect=https%3A%2F%2Fconnect.medcity.net%2Fweb%2Fuxcoe%2Foverview%3Fp_p_id%3Dcom_liferay_blogs_web_portlet_BlogsPortlet%26p_p_lifecycle%3D0%26p_p_state%3Dnormal%26p_p_mode%3Dview%26_com_liferay_blogs_web_portlet_BlogsPortlet_cur%3D1%26_com_liferay_blogs_web_portlet_BlogsPortlet_delta%3D20'
    },
    {
      imageLoc: UXCOEC4C,
      imageAlt: 'Two nurses typing at workstation',
      author: 'Cydney Marsh',
      readTime: '3 Mins Read',
      contentTitle: 'UXCOE Coding for Caregivers Rewind',
      link: 'https://connect.medcity.net/web/uxcoe/overview/-/blogs/uxcoe-coding-for-caregivers-rewind-?_com_liferay_blogs_web_portlet_BlogsPortlet_redirect=https%3A%2F%2Fconnect.medcity.net%2Fweb%2Fuxcoe%2Foverview%3Fp_p_id%3Dcom_liferay_blogs_web_portlet_BlogsPortlet%26p_p_lifecycle%3D0%26p_p_state%3Dnormal%26p_p_mode%3Dview%26_com_liferay_blogs_web_portlet_BlogsPortlet_cur%3D1%26_com_liferay_blogs_web_portlet_BlogsPortlet_delta%3D20'
    },
    {
      imageLoc: SketchTips,
      imageAlt: 'Sketch Orange Diamond',
      author: 'James Matchett',
      readTime: '2 Mins Read',
      contentTitle: 'Neutron Developer & Designer Collaboration',
      link: 'https://connect.medcity.net/web/uxcoe/overview/-/blogs/sketch-tips-part-1-setting-up-for-success-?_com_liferay_blogs_web_portlet_BlogsPortlet_redirect=https%3A%2F%2Fconnect.medcity.net%2Fweb%2Fuxcoe%2Foverview%3Fp_p_id%3Dcom_liferay_blogs_web_portlet_BlogsPortlet%26p_p_lifecycle%3D0%26p_p_state%3Dnormal%26p_p_mode%3Dview%26_com_liferay_blogs_web_portlet_BlogsPortlet_cur%3D1%26_com_liferay_blogs_web_portlet_BlogsPortlet_delta%3D20'
    },
    {
      imageLoc: NeutronIn2020,
      imageAlt: 'HCA Neutron',
      author: 'Cydney Marsh',
      readTime: '5 Mins Read',
      contentTitle: 'Neutron in 2020',
      link: 'https://connect.medcity.net/web/uxcoe/overview/-/blogs/neutron-in-2020-?_com_liferay_blogs_web_portlet_BlogsPortlet_redirect=https%3A%2F%2Fconnect.medcity.net%2Fweb%2Fuxcoe%2Foverview%3Fp_p_id%3Dcom_liferay_blogs_web_portlet_BlogsPortlet%26p_p_lifecycle%3D0%26p_p_state%3Dnormal%26p_p_mode%3Dview%26_com_liferay_blogs_web_portlet_BlogsPortlet_cur%3D1%26_com_liferay_blogs_web_portlet_BlogsPortlet_delta%3D20'
    }
  ];

  return (
    <>
      <NeuRow>
        {BlogPostCardData.map((card, key) => {
          return (
            <BlogCard
              key={key}
              imageLoc={card.imageLoc}
              imageAlt={card.imageAlt}
              author={card.author}
              readTime={card.readTime}
              contentTitle={card.contentTitle}
              raised={true}
              iconSlot={false}
              link={card.link}
            />
          );
        })}
      </NeuRow>
    </>
  );
};
