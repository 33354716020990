import { NeuCol, NeuImage } from '@neutron/react';
import { GuidingPrinciple } from '../../types/interfaces';

//this component is used in GuidingPrinciples.tsx
export const PrincipleElement = ( {imageLoc, imageAlt, title, content}: GuidingPrinciple) => {
  return (
      <NeuCol className="d-flex flex-column align-items-center m-6" sm={1} md={2}>
        <NeuImage className="d-flex mb-6" minHeight='108px' alt={imageAlt} src={imageLoc} maxWidth="100%" height="auto" fluid />
        <h5 className="mb-3 neu-text--orange-60">{title}</h5>
        <p className='lh neu-text--body'>
          {content}
        </p>
      </NeuCol>
  );
};