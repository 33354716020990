import { Route, Routes } from "react-router-dom"
import { Accessibility } from "./accessibility/Accessibility"
import { Blogs } from "./blogs/Blogs"
import { ConnectWithUs } from "./Connect/ConnectWithUs"
import { Home } from "./home/Home"
import { MeetTheTeam } from "./meet-the-team/MeetTheTeam"
import { NeutronDesignSystem } from "./NeutronDesignSystem/NeutronDesignSystem"
import { OurImpact } from "./our-impact/OurImpact"
import { StarterFiles } from "./starter-files/StarterFiles"
interface AppViewsProp {
  scrollToTop: () => void
}

export const ApplicationViews = ({scrollToTop}: AppViewsProp):JSX.Element =>
(
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/neutron-design-system" element={<NeutronDesignSystem />} />
        <Route path="/get-started" element={<StarterFiles scrollToTop={scrollToTop}/>} />
        <Route path="/accessibility" element={<Accessibility />} />
        <Route path="/our-impact" element={<OurImpact />} />
        <Route path="/meet-the-team" element={<MeetTheTeam />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/connect" element={<ConnectWithUs />} />
      </Routes>
)
