import homeBanner from '../../assets/images/hero-images/home-hero.png';
import { Banner } from '../utility-components/Banner';

export const HeroBanner = (): JSX.Element => {
  const bannerData = {
    imageLoc: homeBanner,
    fillColor: '#2075AD',
    bannerTitle: 'UXCOE',
    bannerSubtitle: 'User Experience Center of Excellence',
    bannerText:
      'The UXCOE helps to educate, empower and evangelize how to deliver fantastic user experiences for products!',
    bannerButtons: false
  };

  return (
      <Banner
        fillColor={bannerData.fillColor}
        imageLoc={bannerData.imageLoc}
        bannerTitle={bannerData.bannerTitle}
        bannerSubtitle={bannerData.bannerSubtitle}
        bannerText={bannerData.bannerText}
        bannerButtons={bannerData.bannerButtons}
      />
  );
};
