import { Banner } from '../utility-components/Banner';
import OurImpactBanner from '../../assets/images/hero-images/OurImpactBanner.png';

export const OurImpactHero = (): JSX.Element => {
  const impactBannerData = {
    imageLoc: OurImpactBanner,
    fillColor: '#093865',
    bannerTitle: 'Our Impact',
    bannerSubtitle: null,
    bannerText:
      'Our community centers around sharing, collaborating, and fostering support.',
    bannerButtons: false
  };

  return (
      <Banner
        fillColor={impactBannerData.fillColor}
        imageLoc={impactBannerData.imageLoc}
        bannerTitle={impactBannerData.bannerTitle}
        bannerSubtitle={impactBannerData.bannerSubtitle}
        bannerText={impactBannerData.bannerText}
        bannerButtons={impactBannerData.bannerButtons}
      />
  );
};
