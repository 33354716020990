import {
  NeuCard,
  NeuIcon,
  NeuImage,
  NeuButton
} from '@neutron/react';

type PortraitImageCardProps = {
  imageLoc: string;
  imageAlt: string;
  contentTitle: string;
  content: string;
  buttonText: string;
  raised: boolean;
  iconSlot: boolean;
  downloadLink: string;
};

export const PortraitImageCard = ({
  imageLoc,
  imageAlt,
  contentTitle,
  content,
  buttonText,
  raised,
  iconSlot,
  downloadLink
}: PortraitImageCardProps): JSX.Element => (
  <NeuCard
    width="fit-content"
    className="p-0 d-flex flex-column justify-content-between"
  >
    <NeuImage
      src={imageLoc}
      class="image-gradient d-flex safari-width"
      alt={imageAlt}
      maxWidth="100%"
      height="auto"
      fluid
    />
    <div className="p-4 d-flex flex-fill justify-content-around flex-column">
      <p className="neu-text--h5">{contentTitle}</p>
      <p className="text-wrap">{content}</p>
    </div>
    <NeuButton
      color="primary"
      type="button"
      value={buttonText}
      fill={raised ? 'raised' : 'outline'}
      href={downloadLink}
      target="_blank"
      className="mb-4 align-self-center"
    >
      {buttonText}
      {iconSlot ? <NeuIcon slot="start">file_download</NeuIcon> : null}
    </NeuButton>
  </NeuCard>
);
