import { Banner } from '../utility-components/Banner';
import accessibilityBanner from '../../assets/images/hero-images/accessibility-hero.png';

export const AccessibilityHeroBanner = (): JSX.Element => {
  const bannerData = {
    imageLoc: accessibilityBanner,
    fillColor: '#B7502B',
    bannerTitle: 'Accessibility',
    bannerSubtitle: null,
    bannerText: 'Design with others in mind. Design is inclusive for everyone',
    bannerButtons: false
  };

  return (
    <Banner
      fillColor={bannerData.fillColor}
      imageLoc={bannerData.imageLoc}
      bannerTitle={bannerData.bannerTitle}
      bannerSubtitle={bannerData.bannerSubtitle}
      bannerText={bannerData.bannerText}
      bannerButtons={bannerData.bannerButtons}
    />
  );
};
