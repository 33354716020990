import {NeuImage, NeuFooter, NeuRow, NeuContainer, NeuCol, NeuLink} from '@neutron/react'
import logo from "../../assets/images/HCA-Horizontal-white.png"

export const Footer = ():JSX.Element => {
    const currentYear = new Date().getFullYear()
    return (
        <NeuFooter color="primary-100" >
            <NeuContainer>
                <NeuRow>
                <NeuCol md={6} cols={12} class="neu-text-center neu-text-md-left">
                    <NeuLink ariaLabel="Link to Home Page" href="https://hcahealthcare.com" target='_blank' >
                        <NeuImage width="200px" src={logo} class="mx-auto mx-md-0" alt="HCA"/>
                    </NeuLink>
                </NeuCol>
                <NeuCol md={6} cols={12} class="neu-text-center neu-text-md-right">©{currentYear} HCA Healthcare
                </NeuCol>
            </NeuRow>
        </NeuContainer>
        </NeuFooter>
    )
}
