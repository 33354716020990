import { NeuCol, NeuImage, NeuRow } from '@neutron/react';
// import NeutronShapes from '../../assets/images/neutron-page/neutron-shapes.svg';
import NeutronShapes from '../../assets/images/neutron-page/neutron-shapes.svg';

export const WhatIsNeutron = () => {
  return (
    <NeuCol className="d-flex flex-column flex-md-row align-items-center">
      <NeuRow className='align-items-center'>
        <NeuCol
          sm={12}
          md={8}
          cols={12}
          className="d-flex flex-column justify-content-md-start justify-content-center"
        >
          {/*🖥 Desktop */}
          <h2 className="d-none d-md-block neu-text-weight--heavy mb-6">
            What Is Neutron?
          </h2>
          <h6
            className="mb-2 d-none d-md-block"
          >
            The Digital Product Design Guidelines for HCA Healthcare
          </h6>
          <p className="lh d-none d-md-block">
            Neutron is HCA Healthcare's guide to designing and building digital
            products. This design system was created to tackle our
            organization's most difficult challenges: Efficiency, Consistency
            and Scalability. Our belief is that through the Neutron Design
            System we've developed a system that allows designers to create
            scalable products from a core set of components and ideals, while
            allowing developers to prototype quickly with a focus in
            functionality.
          </p>

          {/*📱 Mobile */}
          <h5
            className="neu-text-weight--heavy neu-text-center mb-6 d-md-none"
          >
            What Is Neutron?
          </h5>
          <div className="d-md-none neu-text--body neu-text-weight--bold neu-text-center">
            The Digital Product Design Guidelines for HCA Healthcare
          </div>
          <p className="neu-text--support neu-text-weight--regular lh d-md-none">
            Neutron is HCA Healthcare's guide to designing and building digital
            products. This design system was created to tackle our
            organization's most difficult challenges: Efficiency, Consistency
            and Scalability. Our belief is that through the Neutron Design
            System we've developed a system that allows designers to create
            scalable products from a core set of components and ideals, while
            allowing developers to prototype quickly with a focus in
            functionality.
          </p>
        </NeuCol>
        <NeuCol cols={4} className="d-flex justify-content-center">
          <NeuImage
            alt="circle square and triangle"
            src={NeutronShapes}
            className="d-none d-md-block big-neutron-logo"
          />
        </NeuCol>
      </NeuRow>
    </NeuCol>
  );
};
