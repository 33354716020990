import { FilterButton } from './FilterButton';
import { FilterState } from '../../types/interfaces';
import expert from '../../assets/icons/starter-files-capacity-levels/expert.svg';
import intermediate from '../../assets/icons/starter-files-capacity-levels/intermediate.svg';
import quickWins from '../../assets/icons/starter-files-capacity-levels/quick-wins.svg';
import '../Uxcoe.scss';
import allTemplates from '../../assets/icons/starter-files-capacity-levels/all-templates.svg';
import { NeuRow, NeuCol } from '@neutron/react';

export const FilterBar = ({ filterState, setFilterState }: FilterState) => {
  const data = [
    {
      textColor: '#976609',
      value: 'quick-wins',
      imageLoc: quickWins,
      imageAlt: 'award style ribbon',
      titleText: 'Quick Wins',
      idValue: 'quick-wins'
    },
    {
      textColor: '#5C6AB8',
      value: 'intermediate',
      imageLoc: intermediate,
      imageAlt: 'prize ribbon',
      titleText: 'Intermediate',
      idValue: 'intermediate'
    },
    {
      textColor: '#467D20',
      value: 'expert',
      imageLoc: expert,
      imageAlt: 'victory wreath around five point star',
      titleText: 'Expert',
      idValue: 'expert'
    },
    {
      textColor: '#124E7F',
      value: 'all',
      imageLoc: allTemplates,
      imageAlt: 'page with list and pencil',
      titleText: 'All Templates',
      idValue: 'all-templates'
    }
  ];

  return (
    <>
      <NeuRow>
        <p className="neu-text--h6 mb-3">Sort Templates by Capacity Level</p>
        <fieldset className="d-flex flex-row px-3 justify-content-between">
          {data.map((filterItem, key) => {
            return (
              <NeuCol key={key} cols={4}>
                <FilterButton
                  filterState={filterState}
                  setFilterState={setFilterState}
                  textColor={filterItem.textColor}
                  value={filterItem.value}
                  imageLoc={filterItem.imageLoc}
                  imageAlt={filterItem.imageAlt}
                  titleText={filterItem.titleText}
                  idValue={filterItem.idValue}
                />
              </NeuCol>
            );
          })}
        </fieldset>
      </NeuRow>
    </>
  );
};
