import '../Uxcoe.scss';
import { NeuCol, NeuContainer, NeuRow } from '@neutron/react';
import { ConnectForm } from '../utility-components/ConnectForm';
import { Footer } from '../footer/Footer';
import { BlogsBanner } from './BlogsBanner';
import { BlogPosts } from './BlogsPosts';

export const Blogs = (): JSX.Element => (
  <NeuContainer fluid>
    <NeuRow>
      <NeuCol>
        <BlogsBanner />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
        <BlogPosts />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
        <ConnectForm />
      </NeuCol>
    </NeuRow>
    <NeuRow>
      <Footer />
    </NeuRow>
  </NeuContainer>
);
