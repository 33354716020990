import { Banner } from '../utility-components/Banner';
import neutronHeroLegos from '../../assets/images/hero-images/neutron-hero.png';

export const HeroBanner = (): JSX.Element => {
  const neutronBanner = {
    imageLoc: neutronHeroLegos,
    fillColor: '#2075AD',
    bannerTitle: 'Neutron Product Design System',
    bannerSubtitle: null,
    bannerText:
      'Neutron is the official digital design system built to support our HCA Healthcare Brand Strategy.',
    bannerButtons: false
  };

  return (
    <Banner
      fillColor={neutronBanner.fillColor}
      imageLoc={neutronBanner.imageLoc}
      bannerTitle={neutronBanner.bannerTitle}
      bannerSubtitle={neutronBanner.bannerSubtitle}
      bannerText={neutronBanner.bannerText}
      bannerButtons={neutronBanner.bannerButtons}
    />
  );
};
