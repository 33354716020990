import { NeuIcon, NeuImage, NeuCard } from '@neutron/react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import '../Uxcoe.scss';

type RouteObj = {
  pathname?: string;
  hash?: string;
  target?: '_self' | '_blank';
};

type CallOutBoxProps = {
  imageLoc: string;
  imageAlt: string;
  callOutText: string;
  route: RouteObj;
  link?: string;
};

export const CallOutBox = ({
  imageLoc,
  imageAlt,
  callOutText,
  route,
  link
}: CallOutBoxProps): JSX.Element => {
  let navigate = useNavigate();

  const clickHandler = (ev: any) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (link) {
      return window.open(link, route.target);
    }
    return navigate(route);
  };

  const [isMouseIn, setIsMouseIn] = useState(false);

  return (
    /*two versions of this based on need:
     - One is if the call out box is a link to an outside site.
     - The second is if the call out box is being used a a button to move to another location on the UXCOE page
    */
    <>
      {route.target ? (
        <NeuCard
          className="d-flex flex-column"
          color={isMouseIn ? 'orange-0' : 'plain-0'}
          onMouseEnter={() => setIsMouseIn(true)}
          onMouseLeave={() => setIsMouseIn(false)}
          onFocus={() => setIsMouseIn(true)}
          onBlur={() => setIsMouseIn(false)}
          aria-label={`${callOutText} Link`}
          href={route.pathname}
          target={route.target}
        >
          <div className="mh8rem d-flex align-items-center justify-content-between">
            <NeuImage
              src={imageLoc}
              alt={''}
              className="mr-2 mr-md-5 d-flex"
              maxWidth="100%"
              height="auto"
              fluid
            />
            <div className="neu-text--h5 d-none d-md-block flex-grow-1">
              {callOutText}
            </div>
            <div className="d-md-none neu-text--caption neu-text--bold flex-grow-1">
              {callOutText}
            </div>
            <NeuIcon className="ml-md-2">arrow_forward_ios</NeuIcon>
          </div>
        </NeuCard>
      ) : (
        <NeuCard
          className="d-flex flex-column"
          color={isMouseIn ? 'orange-0' : 'plain-0'}
          onMouseEnter={() => setIsMouseIn(true)}
          onMouseLeave={() => setIsMouseIn(false)}
          onFocus={() => setIsMouseIn(true)}
          onBlur={() => setIsMouseIn(false)}
          aria-label={`${callOutText} Link`}
          onClick={ev => {
            clickHandler(ev);
          }}
          button
        >
          <div className="mh8rem d-flex align-items-center justify-content-between">
            <NeuImage
              src={imageLoc}
              alt={imageAlt}
              className="mr-2 mr-md-5 d-flex"
              maxWidth="100%"
              height="auto"
              fluid
            />
            <div className="neu-text--h5 d-none d-md-block flex-grow-1">
              {callOutText}
            </div>
            <div className="d-md-none neu-text--caption neu-text--bold flex-grow-1">
              {callOutText}
            </div>
            <NeuIcon className="ml-md-2">arrow_forward_ios</NeuIcon>
          </div>
        </NeuCard>
      )}
    </>
  );
};
