import '../Uxcoe.scss';
import { NeuCol, NeuContainer, NeuRow } from '@neutron/react';
import { ConnectForm } from '../utility-components/ConnectForm';
import { Partnerships } from '../home/Partnerships';
import { OurImpactHero } from './OurImpactBanner';
import { SharedServices } from './SharedServices';
import { Goals } from '../utility-components/Goals';
import { Achievements } from './Achievements';
import { TheImpact } from './TheImpact';
import { Footer } from '../footer/Footer';

export const OurImpact = (): JSX.Element => (
  <NeuContainer fluid>
    <NeuRow>
      <NeuCol>
        <OurImpactHero />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6 pt-6">
      <NeuCol className="my-6 py-6" sm={12} md={10}>
        <SharedServices />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
        <Achievements />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
        <Goals />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
        <TheImpact />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
        <Partnerships />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
        <ConnectForm />
      </NeuCol>
    </NeuRow>
    <NeuRow>
      <Footer />
    </NeuRow>
  </NeuContainer>
);
