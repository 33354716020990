import { NeuImage } from '@neutron/react';

type GoalsComponentProps = {
  imageLoc: string;
  imageAlt: string;
  goal: string;
  slug: string;
};

export const GoalsComponent = ({
  imageLoc,
  imageAlt,
  goal,
  slug
}: GoalsComponentProps): JSX.Element => (
  <div className="d-flex flex-column align-items-center justify-content-between">
    <NeuImage
      className="d-flex"
      src={imageLoc}
      alt={imageAlt}
      maxWidth="100%"
      height="auto"
      fluid
    />
    <h5 className='mt-6'>{goal}</h5>
    <p className="my-5 neu-text-center">{slug}</p>
  </div>
);
