import { NeuCol, NeuContainer, NeuRow } from '@neutron/react';
import { ConnectForm } from '../utility-components/ConnectForm';
import { Footer } from '../footer/Footer';
import { FurtherInfoAccessibility } from './FurtherInfoAccessibilty';
import { HealthierTomorrow } from './HealthierTomorrow';
import { CreatingAccess } from './CreatingAccess';
import { Checklist } from './Checklist';
import { AccessibilityHeroBanner } from './AccessibilityHeroBanner';
import { AccessibilityResources } from './AccessibilityResources';

export const Accessibility = (): JSX.Element => (
  <NeuContainer fluid>
    <NeuRow>
      <NeuCol>
          <AccessibilityHeroBanner/>
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
          <Checklist/>
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
          <CreatingAccess/>
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
          <AccessibilityResources/>
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
        <HealthierTomorrow/>
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
        <FurtherInfoAccessibility />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6">
      <NeuCol sm={12} md={10}>
        <ConnectForm />
      </NeuCol>
    </NeuRow>
    <NeuRow>
      <Footer />
    </NeuRow>
  </NeuContainer>
);
