import { SquadSection } from './SquadSection';
import { colleagueData } from './ColleagueData';

export const SquadBreakdown = () => {
  const squads = [
    {
      name: 'UX Labs',
      description:
        'This squad focuses on upcoming projects that need UX design input. By creating presentation ready products.',
      buttonText: 'Unlock more on the UX Labs Strategy',
      buttonLink: '',
      pictures: [
        colleagueData[0],
        colleagueData[5],
        colleagueData[7]
      ]
    },
    {
      name: 'Product Ops',
      description:
        'This squad focuses on dedicated support for transform-funded product and experience design leveraging the Neutron design system.',
      buttonText: 'Unlock more on the Product Ops Strategy',
      buttonLink: '',
      pictures: [colleagueData[1]]
    },
    {
      name: 'Design Ops',
      description:
        'This squad focuses on systems and tools strategy. Creating toolkits, documentation, training to educate, evangelize, and empower others.',
      buttonText: 'Unlock more on the Design Ops Strategy',
      buttonLink: '',
      pictures: [
        colleagueData[2],
        colleagueData[3],
        colleagueData[4],
        colleagueData[6]
      ]
    }
  ];

  return (
    <>
      {/*Desktop*/}
      <h2 className="d-none d-md-block neu-text-weight--heavy mb-md-6 pb-md-6">
        Our Squad Breakdown
      </h2>
      {/*Mobile*/}
      <p className="d-md-none neu-text--h5 mb-md-6 pb-md-6">
        Our Squad Breakdown
      </p>
      {squads.map((squad, key) => {
        return (
          <SquadSection
            key={key}
            name={squad.name}
            description={squad.description}
            pictures={squad.pictures}
          />
        );
      })}
    </>
  );
};
