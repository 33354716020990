import { NeuCol, NeuContainer, NeuImage, NeuRow } from '@neutron/react';
import '../Uxcoe.scss';

import careTeamLogo from '../../assets/images/partnershiplogos/care-team-logo.png';
import hcaLogo from '../../assets/images/partnershiplogos/hca-healthcare-logo.png';
import healthTrustLogo from '../../assets/images/partnershiplogos/healthtrust-logo.png';
import hutLogo from '../../assets/images/partnershiplogos/hut-logo.png';
import inspireLogo from '../../assets/images/partnershiplogos/inspire-logo.png';
import myHealthOneLogo from '../../assets/images/partnershiplogos/my-health-one-logo.png';
import NeutronLogo from '../../assets/images/partnershiplogos/neutron-logo.png';
import orbitLogo from '../../assets/images/partnershiplogos/orbit-logo.png';
import parallonLogo from '../../assets/images/partnershiplogos/parallon-logo.png';
import patraLogo from '../../assets/images/partnershiplogos/patra-logo.png';
import rhythmLogo from '../../assets/images/partnershiplogos/rhythm-logo.png';
import sarahCannonLogo from '../../assets/images/partnershiplogos/sarah-cannon-logo.png';
import siteCoreLogo from '../../assets/images/partnershiplogos/sitecore-logo.png';

export const Partnerships = (): JSX.Element => {
  return (
    <>
    {/*Desktop*/}
      <h2 className="neu-text-weight--heavy neu-text-md-left mb-md-6 d-none d-md-block">
        Proud Product Partnerships
      </h2>
      {/*Mobile*/}
      <p className="neu-text-weight--bold neu-text--h5 neu-text-center my-3 mb-3 d-md-none">
        Proud Product Partnerships
      </p>
      {/*The most logical approach to placing these images would've been to put them in an array and map them.
      The reason this wasn't done is because the HCA and HUT logos take up one space. They are the only ones that do this.*/}
      <NeuContainer className="mb-6 partnership-grayscale" fluid>
        <NeuRow justify="center" align="center">
          <NeuCol cols={12} class="d-flex justify-content-between p-0">
            <NeuRow justify='center' align='center' className="mx-1 mx-md-6">
              <NeuCol
                sm={3}
                md={12}
                cols={12}
                className="d-flex flex-column  align-items-center"
              >
                <NeuImage
                  className="d-flex my-3 my-md-6"
                  src={healthTrustLogo}
                  alt="Healthtrust"
                  maxWidth="100%"
                  height="auto"
                  fluid
                />

                <NeuImage
                  className="d-flex my-3 my-md-6"
                  src={orbitLogo}
                  alt="Orbit"
                  maxWidth="100%"
                  height="auto"
                  fluid
                />

                <NeuImage
                  className="d-flex my-3 my-md-6"
                  src={rhythmLogo}
                  alt="Rhythm"
                  maxWidth="100%"
                  height="auto"
                  fluid
                />
                <NeuImage
                  className="d-flex my-3 my-md-6"
                  src={careTeamLogo}
                  alt="Care Team"
                  maxWidth="100%"
                  height="auto"
                  fluid
                />
              </NeuCol>
            </NeuRow>

            <NeuRow justify='center' align='center' className="mx-1 mx-md-6">
              <NeuCol
                sm={3}
                md={12}
                cols={12}
                className="d-flex flex-column align-items-center"
              >
                <NeuImage
                  className="d-flex my-3 my-md-6"
                  src={sarahCannonLogo}
                  alt="Sarah Cannon"
                  maxWidth="100%"
                  height="auto"
                  fluid
                />
                <NeuImage
                  className="d-flex my-3 my-md-6"
                  src={siteCoreLogo}
                  alt="Sitecore"
                  maxWidth="100%"
                  height="auto"
                  fluid
                />
                <NeuRow
                  justify="center"
                  align="center"
                  className="d-flex flex-row align-items-center my-3 my-md-6"
                >
                  <NeuCol className="p-0 align-items-center d-flex">
                    <NeuCol cols={7}>
                      <NeuImage
                        className="d-flex mr-md-6"
                        src={hcaLogo}
                        alt="HCA Healthcare"
                        maxWidth="100%"
                        height="auto"
                        fluid
                      />
                    </NeuCol>
                    <NeuCol cols={5}>
                      <NeuImage
                        className="d-flex ml-md-6"
                        src={hutLogo}
                        alt="HUT"
                        maxWidth="100%"
                        height="auto"
                        fluid
                      />
                    </NeuCol>
                  </NeuCol>
                </NeuRow>
                <NeuImage
                  className="d-flex my-3  my-md-6"
                  src={inspireLogo}
                  alt="HCA Inspire"
                  maxWidth="100%"
                  height="auto"
                  fluid
                />
              </NeuCol>
            </NeuRow>
            <NeuRow justify='center' align='center' className="mx-1 mx-md-6">
              <NeuCol
                sm={3}
                md={12}
                cols={12}
                className="d-flex flex-column  align-items-center"
              >
                <NeuImage
                  className="d-flex my-3 my-md-6"
                  src={parallonLogo}
                  alt="Parallon"
                  maxWidth="100%"
                  height="auto"
                  fluid
                />

                <NeuImage
                  className="d-flex my-3 my-md-6"
                  src={NeutronLogo}
                  alt="Neutron"
                  maxWidth="100%"
                  height="auto"
                  fluid
                />
                <NeuImage
                  className="d-flex my-3 my-md-6"
                  src={patraLogo}
                  alt="Patra"
                  maxWidth="100%"
                  height="auto"
                  fluid
                />

                <NeuImage
                  className="d-flex my-3 my-md-6"
                  src={myHealthOneLogo}
                  alt="My Health One"
                  maxWidth="100%"
                  height="auto"
                  fluid
                />
              </NeuCol>
            </NeuRow>
          </NeuCol>
        </NeuRow>
      </NeuContainer>
    </>
  );
};
