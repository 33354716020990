import {
  NeuCard,
  NeuIcon,
  NeuImage,
  NeuButton,
  NeuRow,
  NeuCol
} from '@neutron/react';
import '../Uxcoe.scss';

type ListCellProps = {
  imageLoc: string;
  imageAlt: string;
  cellTitle: string;
  cellDate: string;
  cellContent: string;
  cellIconLoc: string;
  cellIconAlt: string;
  capacityText: string;
  capacityTextColor: string;
  cellPrimaryButtonTitle: string;
  cellPrimaryButtonLink: string;
};

export const ListCell = ({
  imageLoc,
  imageAlt,
  cellTitle,
  cellDate,
  cellContent,
  cellIconLoc,
  cellIconAlt,
  capacityText,
  capacityTextColor,
  cellPrimaryButtonTitle,
  cellPrimaryButtonLink
}: ListCellProps): JSX.Element => {
  return (
    <NeuCard className="p-0 my-6">
      <NeuRow className="align-items-md-center d-flex flex-column flex-md-row">
        <NeuCol md={5} cols={12}>
          <NeuImage className="d-flex gradient-overlay clip-path" src={imageLoc} width='425px' height='276px' alt={imageAlt} />
        </NeuCol>
        <NeuCol className="m-5 m-md-0" sm={10} md={6} cols={12}>
          <NeuRow className="d-flex justify-content-between ">
            <NeuCol md={10}>
              <h5>{cellTitle}</h5>
            </NeuCol>
            <NeuCol className='d-flex flex-column align-items-center' md={2}>
              <NeuImage
                className="d-none d-md-block"
                src={cellIconLoc}
                alt={cellIconAlt}
              />
              <p className={`neu-text--caption neu-text-weight--bold ${capacityTextColor}`}>{capacityText}</p>
            </NeuCol>
          </NeuRow>
          <NeuRow className="mb-5">
            <NeuCol md={12}>
              <p className="neu-text--tags neu-text-weight--medium neu-text--gray-60">
                Updated: {cellDate}
              </p>
            </NeuCol>
          </NeuRow>
          <NeuRow className="mb-3">
            <NeuCol md={12}>
              <p className="neu-text--caption neu-text-weight--regular module line-clamp">
                {cellContent}
              </p>
            </NeuCol>
          </NeuRow>
          <NeuRow className="d-flex justify-content-end">
            <NeuButton
              className="mr-1 d-none d-md-block"
              color="primary"
              type="button"
              fill="raised"
              href={cellPrimaryButtonLink}
              target="_blank"
            >
              {cellPrimaryButtonTitle}
            </NeuButton>
            <NeuButton
              className="d-md-none mx-5"
              color="gray-80"
              size="large"
              target='"_blank"'
              type="button"
              fill="flat"
            >
              <NeuIcon color="primary" btn slot="end">
                chevron_right
              </NeuIcon>
              More Info
            </NeuButton>
          </NeuRow>
        </NeuCol>
      </NeuRow>
    </NeuCard>
  );
};
