import '../Uxcoe.scss';
import { NeuCol, NeuRow } from '@neutron/react';
import { UXUIComponent } from './UXUI/UXUIComponent';

import content from '../../assets/images/home/the-content.svg';
import meal from '../../assets/images/home/the-meal.png';
import UI from '../../assets/images/home/the-ui.png';

export const UXUI = (): JSX.Element => {
  let uxuiData = [
    {
      imageLoc: content,
      imageAlt: 'meal ingredients',
      title: 'The Content',
      slug: 'Just like preparing for a party, you make a shopping list for the raw ingredients and an invitation list. In UX we refer to things like this as the content, information architecture and personas.'
    },
    {
      imageLoc: UI,
      imageAlt: 'meal place setting',
      title: 'UI',
      slug: 'The place settings, decorations, and location are examples of how you want to present the content. Every visual choice that’s made says something about the content, whether intended or not.​'
    },
    {
      imageLoc: meal,
      imageAlt: 'people sitting at a table eating',
      title: 'UX',
      slug: 'Ultimately what matters is how the event makes people feel. User Experience is all about the sum of the parts: content meets interface plus people equals experience. ​'
    }
  ];

  return (
    <>
      <NeuRow className="my-6 pb-6">
        <NeuCol className="my-6">
          {/*Desktop*/}
          <h2 className="neu-text-weight--heavy neu-text-center neu-text-md-left ml-md-6 my-6 d-none d-md-block">
            What is UX/UI?
          </h2>
          {/*Mobile*/}
          <h5 className="neu-text-weight--bold neu-text-center my-3 mb-3 d-md-none">
            What is UX/UI?
          </h5>
          {/*Desktop*/}
          <p className="d-none d-md-block neu-text--h4 ml-md-6">
            User Experience (UX) Design is the practice of determining how a
            user will perceive and interact with a product or service.
          </p>
          {/*Mobile*/}
          <p className="d-md-none">
            User Experience (UX) Design is the practice of determining how a
            user will perceive and interact with a product or service.
          </p>
        </NeuCol>
      </NeuRow>

      <NeuRow className="justify-content-md-around">
        {uxuiData.map((object, key) => {
          return (
            <NeuCol
              key={key}
              sm={12}
              md={3}
              className="d-flex justify-content-center justify-content-md-around"
            >
              <UXUIComponent
                imageLoc={object.imageLoc}
                imageAlt={object.imageAlt}
                title={object.title}
                slug={object.slug}
              />
            </NeuCol>
          );
        })}
      </NeuRow>
    </>
  );
};
