import "../Uxcoe.scss";
import { NeuCol, NeuRow } from "@neutron/react";
import { SharedServicesComponent } from "./SharedServicesComponent"
import handHeart from '../../assets/icons/our-impact-shared-services/hand-heart.svg'
import design from '../../assets/icons/our-impact-shared-services/design.svg'
import creditCard from '../../assets/icons/our-impact-shared-services/credit-card.svg'
import conversations from '../../assets/icons/our-impact-shared-services/conversations.svg'

export const SharedServices = (): JSX.Element => {
  let sharedServicesData = [
    {
      imageLoc: handHeart,
      imageAlt: "",
      title: "User Experience",
      slug: ["Design thinking", "Accessibility", "Product Strategy", "User Research", "Consultation"],
    },
    {
      imageLoc: creditCard,
      imageAlt: "",
      title: "Product Design",
      slug: ["UI Visual Design", "Information Architecture", "Wireframes", "User Validation", "Consultation"]
    },
    {
      imageLoc: design,
      imageAlt: "",
      title: "Design Tools",
      slug: ["Design System &", "Standards", "Design Tools & MSAs", "Vendor Onboarding", "Brand Strategy", "Product Standards"],
    },
    {
      imageLoc: conversations,
      imageAlt: "",
      title: "Strategic Sourcing",
      slug: ["UX Community", "Job descriptions &family", "Resume & Interview", "Review", "Consultation", "Vendor Partnership", "MSAs"],
      }
  ];

  return (
    <>
      <h2 className="neu-text-weight--heavy neu-text-center neu-text-md-left mb-6 pb-6 d-none d-md-block">UXCOE Current Shared-Services Focus</h2>
      <h5 className="neu-text-center mb-6 pb-6 d-md-none">UXCOE Current Shared-Services Focus</h5>
        <NeuRow className="justify-content-center justify-content-md-around">
          {sharedServicesData.map((object , key) => {
            return (
              <NeuCol key={key}  md={3} cols={8} className="vh-70 my-6 my-md-0">
                <SharedServicesComponent
                  imageLoc={object.imageLoc}
                  imageAlt={object.imageAlt}
                  title={object.title}
                  slug={object.slug}
                />
              </NeuCol>
            );
          })}
        </NeuRow>
    </>
  );
};
