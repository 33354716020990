import { ApplicationViews } from "./ApplicationViews";
import { NavBar } from "./nav/NavBar";
import { NeuApp, NeuContent } from "@neutron/react";
import { Drawer } from "./nav/Drawer";
import "./Uxcoe.scss"
import { useRef } from "react";

export const UXCOE = (props: any):JSX.Element => {
  const contentRef = useRef<HTMLNeuContentElement | null>(null);
  const scrollToTop= () => {
      contentRef.current && contentRef.current.scrollToTop(500);
  };
  return (
    <NeuApp>
      <NavBar />
      <Drawer />
      <NeuContent ref={contentRef} scrollEvents>
        <ApplicationViews scrollToTop={scrollToTop}/>
      </NeuContent>
    </NeuApp>
  );
};
