//Image Imports
import userJourneyImage from "../../assets/images/starter-files-cell-images/user-journey.png"
import iaTemplateImage from "../../assets/images/starter-files-cell-images/ia-template.png"
import cutPriceImage from "../../assets/images/starter-files-cell-images/cut-price-cell.png"
import fullPriceImage from "../../assets/images/starter-files-cell-images/full-persona-cell.png"
import ABTestImage from '../../assets/images/starter-files-cell-images/a-b-testing.png'
import accessibilityGathering from '../../assets/images/starter-files-cell-images/accessibility-scorecard.png'
import storyboardTemplateImage from '../../assets/images/starter-files-cell-images/storyboard-template.png'
import createPersonaImage from '../../assets/images/starter-files-cell-images/create-a-persona.png'
import shortQualitativeImage from '../../assets/images/starter-files-cell-images/short-qualitative.svg'
import mediumQualitativeImage from '../../assets/images/starter-files-cell-images/medium-qualitative.svg'
import longQualitativeImage from '../../assets/images/starter-files-cell-images/long-qualitative.svg'
import researchTemplateImage from '../../assets/images/starter-files-cell-images/research-template.svg'
import stakeholderInterviewImage from '../../assets/images/starter-files-cell-images/stakeholder-interview.svg'

//Icon Imports
import quickWinsIcon from "../../assets/icons/starter-files-capacity-levels/quick-wins.svg"
import intermediateIcon from "../../assets/icons/starter-files-capacity-levels/intermediate.svg"
import expertIcon from "../../assets/icons/starter-files-capacity-levels/expert.svg"


const CapacityLevel = {
  quickWins: {loc: quickWinsIcon, alt:"award style ribbon", text: "Quick Wins", texColor: "neu-text--yellow-60"},
  intermediate: {loc:intermediateIcon, alt:"prize ribbon", text: "Intermediate", texColor: "neu-text--purple-60"},
  expert: {loc: expertIcon, alt: "victory wreath around five point star", text: "Expert", texColor: "neu-text--green-60" },
} as const;

const ButtonName = {
  powerPoint: "Download Powerpoint",
  pdf: "Download PDF"
}

export const starterFilesCellData = [
  {
    imageLoc: userJourneyImage,
    imageAlt: "User journey diagram with a curved line represnent emotions over time",
    title: "User Journey Template",
    date: "August 23, 2021",
    content: "According to the Nielsen Norman Group, a journey map is a visualization that focuses on a specific customer's interaction with a product or service. Your product may only touch one part of a user's journey, but it's important for you to understand the user's entire process.",
    iconLoc: CapacityLevel.expert.loc,
    iconAlt: CapacityLevel.expert.alt,
    capacityText: CapacityLevel.expert.text,
    capacityTextColor: CapacityLevel.expert.texColor,
    primaryButtonTitle: ButtonName.powerPoint,
    primaryButtonLink: "https://hcahealthcare.sharepoint.com/:p:/t/Corp_User_Experience/EYo9LAxGyeRAgnf6feTPBYsBHovymVLhLUZxLYiZWIwhSA?e=Cleeik"
  },
  {
    imageLoc: iaTemplateImage,
    imageAlt: "tree graph diagram of a website",
    title: "IA Template",
    date: "August 23, 2021",
    content: "Sitemaps are handy references to have as a resource and adjust as the product evolves based on iterative prototyping and user testing. During the design workflow, a numbering system is often employed to keep everyone on the same page when discussing the product's content.",
    iconLoc: CapacityLevel.intermediate.loc,
    iconAlt: CapacityLevel.intermediate.alt,
    capacityText: CapacityLevel.intermediate.text,
    capacityTextColor: CapacityLevel.intermediate.texColor,
    primaryButtonTitle: ButtonName.powerPoint,
    primaryButtonLink: "https://hcadmx.invisionapp.com/freehand/document/m3f4jxbdf"
  },
  {
    imageLoc: cutPriceImage,
    imageAlt: "persona template with smiling woman and minimal text",
    title: "Cut-Price Persona Template",
    date: "August 23, 2021",
    content: `Cut-price a simplified persona often only derived from marketing data with demographic data dominating. These can usually be done after a UX Stakeholder Interview. It includes only the name, a lifestyle photo, age, occupation, and brief description of traits and habits and a Job to be Done/User Story i.e. "Help me ______ so that _____" .`,
    iconLoc: CapacityLevel.quickWins.loc,
    iconAlt: CapacityLevel.quickWins.alt,
    capacityText: CapacityLevel.quickWins.text,
    capacityTextColor: CapacityLevel.quickWins.texColor,
    primaryButtonTitle: ButtonName.powerPoint,
    primaryButtonLink: "https://hcahealthcare.sharepoint.com/:p:/r/teams/Corp_User_Experience/_layouts/15/Doc.aspx?sourcedoc=%7B02053E02-7733-425E-B367-C481B45C4EA5%7D&file=Cut-price%20Persona%20Template%20Poster.pptx&action=edit&mobileredirect=true&DefaultItemOpen=1"
  },
  {
    imageLoc: fullPriceImage,
    imageAlt: "persona with image of woman and child, brand logos, and lots of text",
    title: "Full Persona Template",
    date: "August 23, 2021",
    content: `Full fledge personas often include the aforementioned, but more detail on literacy levels, purchasing behaviors, likes, dislikes, competitors they consider, brands they see every day, skillset, and psychographic information.`,
    iconLoc: CapacityLevel.expert.loc,
    iconAlt: CapacityLevel.expert.alt,
    capacityText: CapacityLevel.expert.text,
    capacityTextColor: CapacityLevel.expert.texColor,
    primaryButtonTitle: ButtonName.powerPoint,
    primaryButtonLink: "https://hcahealthcare.sharepoint.com/teams/Corp_User_Experience/_layouts/15/AccessDenied.aspx?Source=https%3A%2F%2Fhcahealthcare%2Esharepoint%2Ecom%2Fteams%2FCorp%5FUser%5FExperience%2F%5Flayouts%2F15%2FDoc%2Easpx%3Fsourcedoc%3D%257B5AA6F85A%2DFE9A%2D4009%2D9A73%2D53315A87F418%257D%26file%3DFull%2520Persona%2520Template%2Epptx%26action%3Dedit%26mobileredirect%3Dtrue%26DefaultItemOpen%3D1%26cid%3D30ed21f7%2Df47b%2D4a7e%2Db94f%2D3382363ba50c&correlation=a6b018a0%2D50a6%2Dc000%2Dad8f%2De9fe938cbd96&Type=item&name=d4e17f27%2D1d3c%2D4681%2Daabb%2D78fa98eb18d8&listItemId=2319"
  },
  {
    imageLoc: ABTestImage,
    imageAlt: "two phones held next to each other with the letters 'A' and 'B' over each one.",
    title: "A/B Design Test",
    date: "2 Years Ago",
    content: `Compare and determine quantitative successes and pitfalls of two sketches, wires, comps, or prototypes (e.g. clarity, helpfulness, task success, ease of use rating, trust rating). With an A/B test you can decide which idea is the best to move forward with.`,
    iconLoc: CapacityLevel.intermediate.loc,
    iconAlt: CapacityLevel.intermediate.alt,
    capacityText: CapacityLevel.intermediate.text,
    capacityTextColor: CapacityLevel.intermediate.texColor,
    primaryButtonTitle: ButtonName.pdf,
    primaryButtonLink: "https://connect.medcity.net/documents/365907235/0/A_B+Design+Test.pdf/aa7632f5-3c20-395d-a3dd-8bc02bc34748?version=1.0&t=1558354183030&download=true"
  },
  {
    imageLoc: accessibilityGathering,
    imageAlt: "group of people all with various accessibility needs",
    title: "Accessibility Scorecard",
    date: "2 Years Ago",
    content: `The accessibility scorecard is a set of guidelines for accessible content on the Internet. Use this checklist to ensure your product meets a baseline of accessibility standards required by HCA Healthcare and US Law.`,
    iconLoc: CapacityLevel.expert.loc,
    iconAlt: CapacityLevel.expert.alt,
    capacityText: CapacityLevel.expert.text,
    capacityTextColor: CapacityLevel.expert.texColor,
    primaryButtonTitle: ButtonName.pdf,
    primaryButtonLink: "https://connect.medcity.net/documents/365907235/0/Accessibility+Scorecard.xlsx/5e6808e4-4ae9-8162-c3e9-4fe1f6facb52"
  },
  {
    imageLoc: storyboardTemplateImage,
    imageAlt: "a grid of squares with drawings of people and places",
    title: "Build A Storyboard",
    date: "1 Year Ago",
    content: `Like sequences of scene sketches used to plan videos, UX storyboards visualize a series of events to map out a user’s interaction with the website or application. Use this template to build out your own storyboard to sell the vision of your product or workflow.`,
    iconLoc: CapacityLevel.quickWins.loc,
    iconAlt: CapacityLevel.quickWins.alt,
    capacityText: CapacityLevel.quickWins.text,
    capacityTextColor: CapacityLevel.quickWins.texColor,
    primaryButtonTitle: ButtonName.powerPoint,
    primaryButtonLink: "https://connect.medcity.net/documents/365907235/0/StoryboardTemplate.pptx/49818722-9144-fadb-d40b-2962b4d5cfb8"
  },
  {
    imageLoc: createPersonaImage,
    imageAlt: "persona template with smiling older woman and minimal text",
    title: "Create A Persona",
    date: "2 Years Ago",
    content: `Personas are archetypes of demographic and psychographic behavior based on user groups. Create them from data collected in user interviews, analytics, and tapestry information.`,
    iconLoc: CapacityLevel.quickWins.loc,
    iconAlt: CapacityLevel.quickWins.alt,
    capacityText: CapacityLevel.quickWins.text,
    capacityTextColor: CapacityLevel.quickWins.texColor,
    primaryButtonTitle: ButtonName.powerPoint,
    primaryButtonLink: "https://connect.medcity.net/documents/365907235/0/CreateAPersona.pptx/58033498-d5b9-eecb-4aa6-4a026aa66b81"
  },
  {
    imageLoc: shortQualitativeImage,
    imageAlt: "several chat bubble icons in blob shape with the word Short in the center",
    title: "Short Qualitative Test",
    date: "2 Years Ago",
    content: `Short Qualitative Tests are tested iteratively at the beginning of the design process to make sure you’re hitting the needs of users. This test should be done as a gut check in quick projects, all feeling and empathy, no numbers.`,
    iconLoc: CapacityLevel.intermediate.loc,
    iconAlt: CapacityLevel.intermediate.alt,
    capacityText: CapacityLevel.intermediate.text,
    capacityTextColor: CapacityLevel.intermediate.texColor,
    primaryButtonTitle: ButtonName.pdf,
    primaryButtonLink: "https://connect.medcity.net/documents/365907235/0/Short+Qualitative+Test+Template.pdf/2a9ce07d-f6e9-978e-adf5-091609171742"
  },
  {
    imageLoc: mediumQualitativeImage,
    imageAlt: "several chat bubble icons in blob shape with the word Medium in the center",
    title: "Medium Qualitative Test",
    date: "2 Years Ago",
    content: `A Medium Qualitative and Quantitative Test is a good baseline/benchmark test to be run on websites once a quarter to ensure they are improving. It determines qualitative likes, dislikes, first impressions, aesthetic usability etc. Additionally, it includes some quantitative measurements to determine successes and pitfalls as far as task completion,ease of use, helpfulness and clarity.`,
    iconLoc: CapacityLevel.intermediate.loc,
    iconAlt: CapacityLevel.intermediate.alt,
    capacityText: CapacityLevel.intermediate.text,
    capacityTextColor: CapacityLevel.intermediate.texColor,
    primaryButtonTitle: ButtonName.pdf,
    primaryButtonLink: "https://connect.medcity.net/documents/365907235/0/Med+Qualitative+QuantitativeTest+Template.pdf/6e1b3629-32ff-f1ae-cc94-7607e719c2da"
  },
  {
    imageLoc: longQualitativeImage,
    imageAlt: "several chat bubble icons in blob shape with the word Long in the center",
    title: "Long Qualitative Test",
    date: "2 Years Ago",
    content: `Long Qualitative Tests are a good baseline/benchmark test to be run on websites once a quarter to ensure they are improving. These tests determine qualitative likes, dislikes, first impressions, aesthetic usability etc. They include some quantitative measurements to determine successes and pitfalls as far as task completion,east of use, helpfulness and clarity.`,
    iconLoc: CapacityLevel.intermediate.loc,
    iconAlt: CapacityLevel.intermediate.alt,
    capacityText: CapacityLevel.intermediate.text,
    capacityTextColor: CapacityLevel.intermediate.texColor,
    primaryButtonTitle: ButtonName.pdf,
    primaryButtonLink: "https://connect.medcity.net/documents/365907235/0/Long+Qualitative+Quantitative+Test+Template.pdf/da0e62a7-7292-7442-b687-085b23891b79"
  },
  {
    imageLoc: researchTemplateImage,
    imageAlt: "illustration of a laptop with two web browser windows open",
    title: "Research Template",
    date: "2 Years Ago",
    content: `Use this Research Template to professionally present a summary of your research findings.`,
    iconLoc: CapacityLevel.intermediate.loc,
    iconAlt: CapacityLevel.intermediate.alt,
    capacityText: CapacityLevel.intermediate.text,
    capacityTextColor: CapacityLevel.intermediate.texColor,
    primaryButtonTitle: ButtonName.pdf,
    primaryButtonLink: "https://connect.medcity.net/documents/365907235/0/Research+Template.pdf/07bfc6b0-0f31-6b41-d965-9b7f57280307"
  },
  {
    imageLoc: stakeholderInterviewImage,
    imageAlt: "Illustration of a man and woman sitting at a table talking to each other",
    title: "Stakeholder Interview",
    date: "2 Years Ago",
    content: "For every project, you will want to interview people who can affect the project, and people whom the project affects. Use this template to craft a stakeholder interview and uncover the needs, wants and expectations of your primary stakeholders.",
    iconLoc: CapacityLevel.intermediate.loc,
    iconAlt: CapacityLevel.intermediate.alt,
    capacityText: CapacityLevel.intermediate.text,
    capacityTextColor: CapacityLevel.intermediate.texColor,
    primaryButtonTitle: ButtonName.pdf,
    primaryButtonLink: "https://connect.medcity.net/documents/365907235/0/UXStakeholderInterview_2019.pdf/77d69c1e-0a44-818c-5931-b4b009043fc2?t=1566835564343"
  },
]