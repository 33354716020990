import '../Uxcoe.scss';
import { NeuImage } from '@neutron/react';
import { FilterState, FilterBarObject } from '../../types/interfaces';

export const FilterButton = (props: FilterBarObject & FilterState) => {
  const {
    textColor,
    value,
    imageLoc,
    imageAlt,
    titleText,
    filterState,
    idValue,
    setFilterState
  } = props;
  const handleChange = (e: any) => {
    setFilterState({
      ...filterState,
      [e.target.name]: e.target.value
    });
  };
  return (
    <div className='level-button'>
      <input
        id={idValue}
        type="radio"
        name="level"
        value={value}
        checked={filterState.level === value}
        onChange={handleChange}
      />
      
        <label
          className="neu-text--caption level-label d-flex flex-column align-items-center "
          style={{ color: textColor }}
          htmlFor={idValue}
        >
          <NeuImage className="d-flex" src={imageLoc} alt={imageAlt}/>
          {titleText}
          {filterState.level === value ?
            <div className='active-line mt-1' style={{ background: textColor }}></div> :
            null
          }
        </label>
      </div>
  );
};
