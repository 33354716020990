export const TemplateVideo = () => {
  return (
    <section className="d-flex flex-column align-items-center">
      {/*Desktop*/}
      <h2 className="neu-text-weight--heavy neu-text-md-left mb-md-6 d-none d-md-block align-self-start">
        Watch our Neutron Template Overview
      </h2>
      {/*Mobile*/}
      <h5
        className="neu-text-center mb-3 d-md-none"
      >
        Watch our Neutron Template Overview
      </h5>
      <div className="vid-container">
        <iframe
          className="align-self-start responsive-iframe"
          title="Neutron Design System: Templates Overview"
          src="https://mediaconnect.app.medcity.net/embed?id=0ef5c592-8d95-4bc2-b662-641a9562a371&startAt=00m00s"
          allowFullScreen
        />
      </div>
    </section>
  );
};
