import { NeuImage, NeuCol, NeuRow } from '@neutron/react';
import '../Uxcoe.scss';

type ColleagueQuoteProps = {
  imageLoc: string;
  imageAlt: string;
  quoteText: string;
  colleagueName: string;
  colleagueTitle: string;
  colleagueOrg: string;
};

export const ColleagueQuote = ({
  imageLoc,
  imageAlt,
  quoteText,
  colleagueName,
  colleagueTitle,
  colleagueOrg
}: ColleagueQuoteProps) => (
  <NeuRow className="my-md-6 p-6 d-flex flex-column flex-md-row align-items-center justify-content-center">
    <NeuCol md={4} className="d-flex justify-content-center">
      <NeuCol cols={5} className="p-0 my-5 my-md-0">
        <NeuImage
          className="d-flex"
          src={imageLoc}
          alt={imageAlt}
          maxWidth="100%"
          height="auto"
          fluid
        />
      </NeuCol>
    </NeuCol>
    <NeuCol md={8}>
      <NeuRow>
        <NeuCol>
          {/*🖥 Desktop*/}
          <p className="d-none d-md-block quotation-mark">“</p>
          <p role={'blockquote'} className="d-none d-md-block neu-text--h3 mb-6 pb-6">{quoteText}"</p>
          {/*📱 Mobile*/}
          <p className="d-md-none mobile-quotation-mark">“</p>
          <p role={'blockquote'} className="lh d-md-none neu-text--body neu-text-weight--bold mb-3 pb-3">
            {quoteText}"
          </p>
        </NeuCol>
      </NeuRow>
      <NeuRow>
        <NeuCol className="d-flex flex-row align-items-baseline">
          {/*🖥 Desktop*/}
          <div className="d-none d-md-block neu-text--h5">{colleagueName} |</div>
          <div className="d-none d-md-block ml-1 text-align-center">
            {colleagueTitle}
          </div>
          {/*📱 Mobile*/}
          <div className="d-md-none neu-text--product">{colleagueName} |</div>
          <div className="ml-1 neu-text--caption neu-text-weight--regular d-md-none">
            {colleagueTitle}
          </div>
        </NeuCol>
      </NeuRow>
      <NeuRow>
        <NeuCol>
          {/*🖥 Desktop*/}
          <div className="d-none d-md-block">{colleagueOrg}</div>
          {/*📱 Mobile*/}
          <div className="d-md-none neu-text--caption neu-text-weight--regular">
            {colleagueOrg}
          </div>
        </NeuCol>
      </NeuRow>
    </NeuCol>
  </NeuRow>
);
