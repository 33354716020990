import { Banner } from '../utility-components/Banner';
import starterFilesBannerImage from '../../assets/images/hero-images/starter-files.png';

export const HeroBanner = (): JSX.Element => {
  const startFilesBanner = {
    imageLoc: starterFilesBannerImage,
    fillColor: '#093865',
    bannerTitle: 'Getting Started',
    bannerSubtitle: 'There are many ways to get started no matter your expertise.',
    bannerText:
      'Review all of our toolkits below from Quick Wins, Intermediate to Expert levels.',
    bannerButtons: true
  };

  return (
      <Banner
        fillColor={startFilesBanner.fillColor}
        imageLoc={startFilesBanner.imageLoc}
        bannerTitle={startFilesBanner.bannerTitle}
        bannerSubtitle={startFilesBanner.bannerSubtitle}
        bannerText={startFilesBanner.bannerText}
        bannerButtons={startFilesBanner.bannerButtons}
      />
  );
};
