import "../Uxcoe.scss";
import { useNavigate } from "react-router-dom";
import {
  NeuButton,
  NeuCol,
  NeuIcon,
  NeuRow,
} from "@neutron/react";
import { PortraitImageCard } from "../utility-components/PortraitImageCard";
import personaImage from "../../assets/images/home/cut-price-persona.png"
import neutronPPTImage from "../../assets/images/home/neutron-power-point.png"
import storyboardImage from "../../assets/images/home/storyboard.png"

import "../Uxcoe.scss";

export const Templates = (): JSX.Element => {
  let navigate = useNavigate()
  let templateCardData = [
    {
      imageLoc: storyboardImage,
      imageAlt: "storyboards",
      contentTitle: "Storyboard Kit",
      content:
        "Storyboards are a great way to narrate how your product plays a part in the user’s life.",
      buttonText: "Storyboard Discovery Kit",
      downloadLink: "https://hcadmx.invisionapp.com/assets/15657866/198607516/166D8523D6FC2128B4E42B47B05F05B14AD0DB5C04699262EBF90E49D96C6CD3/download?cacheVersion=1166D8523D6FC2128B4E42B47B05F05B14AD0DB5C04699262EBF90E49D96C6CD3"
    },
    {
      imageLoc: neutronPPTImage,
      imageAlt: "Neutron Power Point slides",
      contentTitle: "Neutron PowerPoint",
      content:
        "The Neutron Design System is now available for any role at HCA Healthcare to begin design.",
      buttonText: "Neutron PowerPoint Design",
      downloadLink: "https://hcahealthcare.sharepoint.com/:p:/t/Corp_User_Experience/EcCiWpPgxKlGs9XW7ynLwJYBzLZ7qUEWnzKvIhRh9gNTmQ?e=x2HS7j"
    },
    {
      imageLoc: personaImage,
      imageAlt: "cut price pesrona",
      contentTitle: "Cut Price Personas Kit",
      content:
        "Based on data, center your team around realistic user archetypes to design and develop for clients.",
      buttonText: "Cut-Price Persona Template",
      downloadLink: "https://hcahealthcare.sharepoint.com/:p:/r/teams/Corp_User_Experience/_layouts/15/Doc.aspx?sourcedoc=%7B02053E02-7733-425E-B367-C481B45C4EA5%7D&file=Cut-price%20Persona%20Template%20Poster.pptx&action=edit&mobileredirect=true&DefaultItemOpen=1"
    },
  ];


  return (
    <>
      <NeuRow>
        <h2 className="neu-text-weight--heavy neu-text-md-left mb-md-6 d-none d-md-block ml-md-6">
          Our Popular Self-Serve Templates
        </h2>
      </NeuRow>
      <NeuRow className="d-flex justify-content-around">
        {templateCardData.map((card, key) => {
          return (
            <NeuCol
              key={key}
              sm={12}
              md={4}
              cols={12}
              className="d-flex justify-content-center"
            >
              <PortraitImageCard
                imageLoc={card.imageLoc}
                imageAlt={card.imageAlt}
                contentTitle={card.contentTitle}
                content={card.content}
                buttonText={card.buttonText}
                raised={false}
                downloadLink={card.downloadLink}
                iconSlot
              />
            </NeuCol>
          );
        })}
      </NeuRow>
      <NeuRow className="m-md-6 p-md-6">
        <NeuCol className="d-flex justify-content-center">
          {/*Mobile*/}
          <NeuButton color={'primary'} onClick={() => navigate("/get-started")} className="d-none d-md-block">
            Explore More UX Templates and Resources
            <NeuIcon color='plain-0'>chevron_right</NeuIcon>
          </NeuButton>
          {/*Mobile*/}
          <NeuButton className="d-md-none" onClick={() => navigate("/get-started")} >
            More UX Resources
            <NeuIcon>chevron_right</NeuIcon>
          </NeuButton>
        </NeuCol>
      </NeuRow>
    </>
  );
};

