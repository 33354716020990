import { NeuCol, NeuImage, NeuRow } from '@neutron/react';

import neutronLogo from '../../assets/images/neutron-page/Neutron-fullcolor-horizontal.png';
import beforeNeutron from '../../assets/images/neutron-page/try-neutron/before-phone-image.png';
import afterNeutron from '../../assets/images/neutron-page/try-neutron/after-phone-image.png';

import videoIcon from '../../assets/icons/neutron-tools/design.svg';
import webDev from '../../assets/icons/neutron-tools/web-development.svg';
import { CallOutBox } from '../utility-components/CallOutBox';

export const NeutronTools = () => {
  const callOutData = [
    {
      imageLoc: videoIcon,
      imageAlt: "pencil and ruler arranged in an 'X'",
      callOutText: 'Neutron for Designers',
      link: 'https://hcadmx.invisionapp.com/dsm/hcadmx/neutron/nav/5fa7da2742ad0f001826f24f/folder/60ec882dd37f2cc4cf3008a4'
    },
    {
      imageLoc: webDev,
      imageAlt: 'browser window with brackets representing code.',
      callOutText: 'Neutron For Developers',
      link: 'https://hcadmx.invisionapp.com/dsm/hcadmx/neutron/nav/5fa7da2742ad0f001826f24f/folder/5e948ea10922557b816b40b9?version=618bcbe9a0d5d8d0416cc656&mode=preview'
    }
  ];
  return (
    <>
      <NeuRow className="d-flex">
        <NeuCol md={8} cols={12}>
          {/*Desktop*/}
          <h2 className="neu-text-weight--heavy neu-text-center neu-text-md-left mb-6 d-none d-md-block">
            Try Neutron Tools Catered for You
          </h2>
          {/*Mobile*/}
          <h5 className="neu-text-weight--heavy neu-text-center mb-6 d-md-none">
            Try Neutron Tools Catered for You
          </h5>
        </NeuCol>
      </NeuRow>
      <NeuRow>
        <NeuCol
          className="d-flex flex-column justify-content-center"
          sm={12}
          md={5}
          cols={12}
        >
          {callOutData.map((callOut, key) => {
            return (
              <div key={key} className="my-md-5">
                <CallOutBox
                  imageLoc={callOut.imageLoc}
                  imageAlt={callOut.imageAlt}
                  callOutText={callOut.callOutText}
                  route={{
                    pathname: callOut.link,
                    target: '_blank'
                  }}
                />
              </div>
            );
          })}
        </NeuCol>
        <NeuCol md={6} cols={12}>
          <NeuRow className="d-flex flex-row">
            <NeuCol
              cols={6}
              className="d-flex flex-column align-items-start align-items-md-center justify-content-center"
            >
              {/*Desktop*/}
              <h5 className="d-none d-md-block mb-5">Before Neutron</h5>
              {/*Mobile*/}
              <p className="d-md-none mb-2 neu-text--caption">Before Neutron</p>
              <NeuImage
                className="d-flex before-neutron"
                alt="older iPhone with hard to read and use webpage displayed"
                src={beforeNeutron}
              />
            </NeuCol>
            <NeuCol
            sm={3}
            md={6}
              cols={6}
              className="d-flex flex-column align-items-end align-items-md-center justify-content-end"
            >
              <NeuImage
              maxHeight='auto'
              maxWidth='30rem'
                className="d-flex mb-2"
                alt="Neutron Design System"
                src={neutronLogo}
                fluid
              />
              <NeuImage className="d-flex after-neutron" alt="large newer iPhone with easy to read and understand Neutron-designed webpage displayed" src={afterNeutron} />
            </NeuCol>
          </NeuRow>
        </NeuCol>
      </NeuRow>
    </>
  );
};
