import { NeuButton, NeuCard, NeuCol, NeuImage, NeuRow } from '@neutron/react';
import visuallyImpairedMan from '../../assets/images/accessibility/visually-impaired-man.png';

export const FurtherInfoAccessibility = () => {
  return (
    <>
      {/*Desktop*/}
      <h2 className="d-none d-md-block neu-text-weight--heavy my-6">
        For Further Info
      </h2>
      {/*Mobile*/}
      <h5 className="d-md-none neu-text-weight--heavy my-6">
        For Further Info
      </h5>
      {/*Desktop*/}
      <NeuCard className="d-none d-md-block ml-0 p-0" height="fit-content">
        <NeuRow className="d-flex justify-content-between align-items-center">
          <NeuCol className="ml-6 pl-6" sm={12} md={5}>
            <h3 id="WCAG" className="mb-5 neu-text--h3 neu-text-weight--light">
              Visit WCAG
            </h3>
            <div className="neu-text--body mb-2">What is WCAG?</div>
            <p className="mb-5 lh neu-text--body">
              The Web Content Accessibility Guidelines are part of a series of
              web accessibility guidelines published by the Web Accessibility
              Initiative of the World Wide Web Consortium.
            </p>
            <NeuButton
              className="mt-6"
              color="primary"
              href="https://hcadmx.invisionapp.com/dsm/hcadmx/neutron"
              target='"_blank"'
              type="button"
              fill="raised"
            >
              Check out WCAG site
            </NeuButton>
          </NeuCol>
          <NeuCol
            className="d-flex justify-content-end  align-items-center"
            sm={0}
            md={5}
          >
            <NeuImage
              alt="visually impaired man thinking about accessibility"
              src={visuallyImpairedMan}
              className="d-flex"
            />
          </NeuCol>
        </NeuRow>
      </NeuCard>

      {/*Mobile*/}
      <section className="d-md-none">
        <NeuImage
          alt="visually impaired man thinking about accessibility"
          src={visuallyImpairedMan}
          className="p-0 d-flex"
          fluid
        />
        <NeuCol className="ml-6 pl-6" sm={12} md={5}>
          <h3 id="WCAG" className="mb-5 neu-text-weight--light">
            Visit WCAG
          </h3>
          <p className="neu-text--body mb-2">What is WCAG?</p>
          <p className="mb-5 lh neu-text--body">
            The Web Content Accessibility Guidelines are part of a series of web
            accessibility guidelines published by the Web Accessibility
            Initiative of the World Wide Web Consortium.
          </p>
          <NeuButton
            className="mt-6"
            color="primary"
            href="https://hcadmx.invisionapp.com/dsm/hcadmx/neutron"
            target='"_blank"'
            type="button"
            fill="raised"
            full
          >
            Check out WCAG site
          </NeuButton>
        </NeuCol>
      </section>
    </>
  );
};
