import { NeuCard, NeuCol, NeuImage } from '@neutron/react';
import { useState } from 'react';
import '../Uxcoe.scss';

type BlogCardProps = {
  imageLoc: string;
  imageAlt: string;
  author: string;
  readTime: string;
  contentTitle: string;
  raised: boolean;
  iconSlot: boolean;
  link: string;
};

export const BlogCard = ({
  imageLoc,
  imageAlt,
  author,
  readTime,
  contentTitle,
  link
}: BlogCardProps): JSX.Element => {
  const [isMouseIn, setIsMouseIn] = useState(false);

  return (
    <NeuCol sm={12} md={4}>
      <NeuCard
        className={isMouseIn ? `shadow-3 p-0 blog-card` : `p-0 blog-card`}
        onMouseEnter={() => setIsMouseIn(true)}
        onMouseLeave={() => setIsMouseIn(false)}
        onFocus={() => setIsMouseIn(true)}
        onBlur={() => setIsMouseIn(false)}
        href={link}
        target="_blank"
        button
      >
        <NeuImage
          src={imageLoc}
          class="image-gradient d-flex safari-width"
          alt={imageAlt}
          maxWidth="100%"
          height="auto"
          fluid
        />
        <section className="d-flex flex-column justify-content-between p-3">
          <div className="d-flex flex-row">
            <div className="mr-1 neu-text--caption neu-text--gray-60 neu-text--h6">
              BY
            </div>
            <div className="flex-fill neu-text--caption neu-text--gray-100">
              {author}
            </div>
            <div className="neu-text--caption neu-text--green-60 neu-text-right mb-1">
              {readTime}
            </div>
          </div>
          <h5 className="neu-text--gray-90 blog-flex my-2"
          >
            {contentTitle}
          </h5>
        </section>
      </NeuCard>
    </NeuCol>
  );
};
