import { InfoBlock } from './InfoBlock';
import { AccessibilityGroupComponentText } from './BlockTextComponents';
import accessibilityGroup from '../../assets/images/accessibility/accessibility-group.svg';
import { NeuRow, NeuCol, NeuImage } from '@neutron/react';
import kineticPermanent from '../../assets/images/accessibility/accessibility-types/kinetic/permanent.svg';
import kineticTemporary from '../../assets/images/accessibility/accessibility-types/kinetic/temporary.svg';
import kineticSituational from '../../assets/images/accessibility/accessibility-types/kinetic/situational.svg';
import visionPermanent from '../../assets/images/accessibility/accessibility-types/vision/permanent.svg';
import visionTemporary from '../../assets/images/accessibility/accessibility-types/vision/temporary.svg';
import visionSituational from '../../assets/images/accessibility/accessibility-types/vision/situational.svg';
import hearingPermanent from '../../assets/images/accessibility/accessibility-types/hearing/permanent.svg';
import hearingTemporary from '../../assets/images/accessibility/accessibility-types/hearing/temporary.svg';
import hearingSituational from '../../assets/images/accessibility/accessibility-types/hearing/situational.svg';
import verbalPermanent from '../../assets/images/accessibility/accessibility-types/verbal/permanent.svg';
import verbalTemporary from '../../assets/images/accessibility/accessibility-types/verbal/temporary.svg';
import verbalSituational from '../../assets/images/accessibility/accessibility-types/verbal/situational.svg';

const circumstanceData = [
  {
    title: 'Kinetic',
    content:
      'It’s critical to be mindful of movement limitations amongst your users. By keeping everything easily accessible, we help humans with a kinetic disability accomplish what they need.',
    gridData: [
      {
        imageLoc: kineticPermanent,
        imageAlt: 'a one armed woman',
        description: 'one limb'
      },
      {
        imageLoc: kineticTemporary,
        imageAlt: 'man with arm in sling',
        description: 'arm injury'
      },
      {
        imageLoc: kineticSituational,
        imageAlt: 'woman in chair holding baby',
        description: 'new parent'
      }
    ]
  },
  {
    title: 'Vision',
    content:
      'We must provide a variety of ways to view a product. In some cases, users can not see at all or might be distracted by our experiences. Add voice features and more to assist.',
    gridData: [
      {
        imageLoc: visionPermanent,
        imageAlt: 'a man with a cane, a dog, and wearing dark glasses',
        description: 'Blind'
      },
      {
        imageLoc: visionTemporary,
        imageAlt: 'An older woman',
        description: 'Cataracts'
      },
      {
        imageLoc: visionSituational,
        imageAlt: 'A person driving a vehicle holding a phone to their ear',
        description: 'Distracted Driver'
      }
    ]
  },
  {
    title: 'Hearing',
    content:
      'Audible limitations are also a concern of accessibility guidelines. By adding subtitles and other cues, we help various people get the whole experience of our products.',
    gridData: [
      {
        imageLoc: hearingPermanent,
        imageAlt: 'a man signing',
        description: 'Deaf'
      },
      {
        imageLoc: hearingSituational,
        imageAlt: 'child sitting in a chair holding ear',
        description: 'ear infection'
      },
      {
        imageLoc: hearingTemporary,
        imageAlt: 'a man and woman with two children running, dog and cat too.',
        description: 'Loud Home'
      }
    ]
  },
  {
    title: 'Verbal',
    content:
      'We must provide multiple routes to communicate. By adding a chat feature and different languages, we help several people access an entire experience.',
    gridData: [
      {
        imageLoc: verbalPermanent,
        imageAlt: 'a woman standing',
        description: 'Non-Verbal'
      },
      {
        imageLoc: verbalSituational,
        imageAlt: 'a woman with a face mask one',
        description: 'Laryngitis'
      },
      {
        imageLoc: verbalTemporary,
        imageAlt: 'a man gesturing',
        description: 'Heavy Accent'
      }
    ]
  }
];

const accessibilityTypes = ['Permanent', 'Temporary', 'Situational'];

export const CreatingAccess = () => {
  return (
    <>
      <h2 className="d-none d-md-block neu-text-weight--heavy my-6 py-6">
        Creating Access No Matter Circumstance
      </h2>
      <h5 className="d-md-none neu-text-weight--heavy neu-text-start my-6 py-6">
        Creating Access No Matter Circumstance
      </h5>
      <InfoBlock
        text={<AccessibilityGroupComponentText />}
        imageLoc={accessibilityGroup}
        imageAlt={
          'group of people of various nationalities and accessibility needs'
        }
      />
      <NeuRow className="d-flex flex-column-reverse flex-md-row justify-content-center">
        <NeuCol md={6}>
          <NeuRow className='vh-100'>
            {circumstanceData.map((singleCircumstance, key) => {
              return (
                <NeuCol
                  key={key}
                  sm={12}
                  md={12}
                  className="my-6 d-flex flex-column justify-content-center "
                >
                  <h5 className="d-none d-md-block my-2">{singleCircumstance.title}</h5>
                  <div className="d-md-none my-2 neu-text-body neu-text-weight--bold">{singleCircumstance.title}</div>
                  <p className="d-none d-md-block lh">{singleCircumstance.content}</p>
                  <p className="neut-text--support d-md-none lh">{singleCircumstance.content}</p>
                </NeuCol>
              );
            })}
          </NeuRow>
        </NeuCol>
        <NeuCol md={6} className="d-flex flex-column justify-content-around">
          <NeuRow>
            <NeuCol className="d-flex flex-row">
              {accessibilityTypes.map((type, key) => {
                return (
                  <NeuCol
                    key={key}
                    className="align-items-center "
                    sm={4}
                    md={4}
                  >
                    <div role='definition' className="neu-text--body neu-text-weight--bold neu-text-center">
                      {type}
                    </div>
                  </NeuCol>
                );
              })}
            </NeuCol>
          </NeuRow>
          <NeuRow>
            {circumstanceData.map((need, key) => {
              return (
                <NeuCol key={key} className="my-6 d-flex flex-row">
                  {need.gridData.map((singleNeed, key) => {
                    return (
                      <NeuCol
                        key={key}
                        className="d-flex flex-column align-items-center justify-content-end"
                        sm={12}
                        md={4}
                      >
                        <NeuImage
                          alt={singleNeed.imageAlt}
                          src={singleNeed.imageLoc}
                          fluid
                        />
                        <div role="definition" className="mt-2 neu-text--support">
                          {singleNeed.description}
                        </div>
                      </NeuCol>
                    );
                  })}
                </NeuCol>
              );
            })}
          </NeuRow>
        </NeuCol>
      </NeuRow>
    </>
  );
};
