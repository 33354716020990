import '../Uxcoe.scss';
import { NeuCol, NeuContainer, NeuRow } from '@neutron/react';
import { HeroBanner } from './HeroBanner';
import { NeutronTools } from './NeutronTools';
import { FurtherInfo } from './FurtherInfo';
import { GetStartedNeutron } from './GetStartedNeutron';
import { Testimonials } from './NeutronTestimonial';
import { TemplateVideo } from './TemplateVideo';
import { GuidingPrinciples } from './GuidingPrinciples';
import { Legos } from './Legos';
import { ConnectForm } from '../utility-components/ConnectForm';
import { Footer } from '../footer/Footer';
import { TrainingDays } from './TrainingDays';
import { WhatIsNeutron } from './WhatIsNeutron';

export const NeutronDesignSystem = (): JSX.Element => (
  <NeuContainer fluid>
    <NeuRow>
      <NeuCol>
        <HeroBanner />
      </NeuCol>
    </NeuRow>
      <NeuRow className="justify-content-center my-6 pt-6">
        <NeuCol sm={12} md={10}>
          <WhatIsNeutron />
        </NeuCol>
      </NeuRow>
    <NeuRow className="justify-content-center my-6 py-6">
      <NeuCol sm={12} md={10}>
        <GuidingPrinciples />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6 py-6">
      <NeuCol sm={12} md={10} className="p-0 px-md-3">
        <Legos />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6 py-6">
      <NeuCol sm={12} md={10}>
        <TemplateVideo />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6 pt-6">
      <NeuCol sm={12} md={10}>
        <TrainingDays />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6 py-6">
      <NeuCol sm={12} md={10}>
        <GetStartedNeutron />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6 py-6">
      <NeuCol sm={12} md={10} className="p-0 px-md-3">
        <NeutronTools />
      </NeuCol>
    </NeuRow>
    <NeuRow className="colleague-quote justify-content-center my-6 py-6">
      <NeuCol sm={12} md={10}>
        <Testimonials />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6 py-6">
      <NeuCol sm={12} md={10}>
        <FurtherInfo />
      </NeuCol>
    </NeuRow>
    <NeuRow className="justify-content-center my-6 py-6">
      <NeuCol sm={12} md={10}>
        <ConnectForm />
      </NeuCol>
    </NeuRow>
    <NeuRow>
      <Footer />
    </NeuRow>
  </NeuContainer>
);
