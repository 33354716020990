import { useNavigate } from 'react-router-dom';
import {
  NeuImage,
  NeuToolbar,
  NeuTablist,
  NeuTab,
  NeuLink,
  NeuDrawerButton,
  NeuIcon,
  NeuTitle,
  NeuHeader
} from '@neutron/react';

import logo from '../../assets/images/home/HCA_logoST_c.png';

export const NavBar = (): JSX.Element => {
  let navigate = useNavigate();
  return (
    <NeuHeader>
      <NeuToolbar top color="primary-100" nav>
        <NeuDrawerButton
          text="Menu"
          className="d-md-none"
          slot="start"
          drawer="first"
        >
          <NeuIcon>menu</NeuIcon>
        </NeuDrawerButton>
        <NeuLink
          onClick={() => navigate('/')}
          ariaLabel="Title Link to Homepage"
          slot="secondary"
        >
          <NeuTitle
            className="pl-md-0 neu-text-center neu-text-md-left"
            color="plain-0"
          >
            User Experience Center of Excellence
          </NeuTitle>
        </NeuLink>
      </NeuToolbar>

      <NeuToolbar color="plain-0" className="d-none d-md-block shadow">
        <NeuLink nav slot="start" ariaLabel="Logo Link to Home Page" role={'link'} onClick={() => navigate('/')}>
          <NeuImage
            width="100px"
            src={logo}
            className="pl-md-0 mx-auto mx-md-0 px-md-2"
            alt="HCA Healthcare"
          />
        </NeuLink>
        <NeuTablist class="d-none d-md-block mx-4" slot="secondary">
          <NeuTab large tab="home" onClick={() => navigate('/')}>
            Home
          </NeuTab>
          <NeuTab large tab="get-started "onClick={() => navigate('/get-started')}>
            Get Started
          </NeuTab>
          <NeuTab large tab='neutron' onClick={() => navigate('/neutron-design-system')}>
            Neutron
          </NeuTab>
          <NeuTab large tab='accessibility' onClick={() => navigate('/accessibility')}>
            Accessibility
          </NeuTab>
          <NeuTab large tab='our-impact' onClick={() => navigate('/our-impact')}>Our Impact</NeuTab>
          <NeuTab large tab='team' onClick={() => navigate('/meet-the-team')}>
            Meet The Team
          </NeuTab>
          <NeuTab large tab='blogs' onClick={() => navigate('/blogs')}>Blogs</NeuTab>
          <NeuTab large tab='connect' onClick={() => navigate('/connect')}>Connect With Us</NeuTab>
        </NeuTablist>
      </NeuToolbar>
    </NeuHeader>
  );
};
